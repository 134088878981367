import * as Sentry from '@sentry/react'
import axios from 'axios'

export const apiWS = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const apiCep = axios.create({
  baseURL: process.env.REACT_APP_API_CEP_URL,
})

export const apiCnpj = axios.create({
  baseURL: process.env.REACT_APP_API_CNPJ_URL,
})

const errorResponseMessages = {
  invalidToken: 'Token login inválido.',
}

const logout = () => {
  window.localStorage.removeItem('accessToken')
  window.location.reload()
}
const logoutTimer = () => {
  let logoutTimer = null
  if (logoutTimer) {
    clearTimeout(logoutTimer)
    logoutTimer = null
  }
  logoutTimer = setTimeout(logout, 3600000)
}

apiWS.interceptors.response.use(
  (response) => {
    logoutTimer()
    return response
  },
  (error) => {
    logoutTimer()

    const { message, httpstatus } = error.response.data

    if (message) {
      error.message = message
    } else {
      error.message = 'Ocorreu um erro com sua requisição'
    }

    if (httpstatus === 400 && message === errorResponseMessages.invalidToken) {
      /*  window.localStorage.clear()
      window.location.replace('/login') */

      return
    }
    Sentry.captureException(error)

    return Promise.reject(error)
  }
)

export const geoApi = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api/geocode',
  params: {
    key: process.env.REACT_APP_GOOGLE_API_KEY,
  },
})
