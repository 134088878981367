import { Flex, Heading, HStack } from '@chakra-ui/layout'
import { Input } from 'components/Input/Input'
import Select from 'components/Select'

import { IPointsBenefitsFormProps } from '../BenefitsForm.interface'

const CashBackBenefitsForm = ({ formik, isEdit }: IPointsBenefitsFormProps) => {
  return (
    <Flex gap={'2rem'} flexDirection={'column'} background="white">
      <HStack width={'100%'}>
        <Heading fontWeight={'semibold'} size={'md'} alignSelf={'start'}>
          Cashback
        </Heading>
        <Input
          nothingLabel={true}
          variant="switch"
          name={'isvalorcashbackenabled'}
        />
      </HStack>

      <HStack width={'100%'} spacing={'8'}>
        <HStack width={'50%'} alignItems={'flex-end'}>
          <Select
            label="Referência"
            options={[
              { label: 'Em valor', value: 'V' },
              { label: 'Em percentual', value: 'P' },
            ]}
            placeholder="Selecione a referencia"
            width={'100%'}
            isDisabled={!formik.values.isvalorcashbackenabled || isEdit}
            variant={'filled'}
            errorMessage={formik.errors.referenciacashback}
            name={'referenciacashback'}
          />
        </HStack>
        <HStack width={'50%'}>
          <Input
            currency
            precision={'3'}
            prefix={
              formik.values.referenciacashback === 'V' ? 'R$ ' : undefined
            }
            suffix={formik.values.referenciacashback === 'P' ? ' %' : undefined}
            label={'Cashback'}
            name={'auferircashback'}
            placeholder="Digite o valor do cashback."
            chakraVariant="filled"
            isDisabled={
              !formik.values.isvalorcashbackenabled ||
              isEdit ||
              formik.values.referenciacashback === ''
            }
          />
        </HStack>
      </HStack>
    </Flex>
  )
}

export default CashBackBenefitsForm
