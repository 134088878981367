import { Box } from '@chakra-ui/react'

import AppRoutes from './pages'

export const App = () => (
  <Box
    textAlign="center"
    fontSize="xl"
    display={'flex'}
    w={'100%'}
    position={'relative'}
  >
    <AppRoutes />
  </Box>
)
