import { Flex, HStack } from '@chakra-ui/layout'
import { Input } from 'components/Input/Input'
import Select from 'components/Select'

import { IInitialBenefitsForms } from '../BenefitsForm.interface'

const InitialBenefitsForms = ({
  formik,
  isEdit,
  GroupOfClientsOptions,
}: IInitialBenefitsForms) => {
  return (
    <Flex gap={'2rem'} flexDirection={'column'} background="white">
      <Select
        label="Grupo de clientes"
        options={GroupOfClientsOptions || [{ label: '', value: '' }]}
        placeholder={
          GroupOfClientsOptions
            ? 'Selecione o grupo de clientes'
            : 'Selecione um ponto de venda antes'
        }
        width={'100%'}
        isDisabled={!GroupOfClientsOptions?.length || isEdit}
        name={'grupoclienteid'}
        errorMessage={formik.errors.grupoclienteid}
      />

      <HStack width={'100%'} spacing={'8'}>
        <HStack alignItems={'flex-end'} width={'50%'}>
          <Input
            pr={110}
            label={'Descrição'}
            name={'descricao'}
            placeholder={'Digite a descrição do benefício.'}
            isDisabled={isEdit}
          />
        </HStack>
        <HStack width={'50%'}>
          <Input
            label={'Vigência Inicial'}
            type={'date'}
            name={'vigenciainicial'}
            placeholder={'Digite a data inicial.'}
            isDisabled={isEdit}
          />
          <Input
            label={'Vigência Final'}
            type={'date'}
            name={'vigenciafinal'}
            placeholder={'Digite a data final.'}
            isDisabled={isEdit}
          />
        </HStack>
      </HStack>
    </Flex>
  )
}

export default InitialBenefitsForms
