import { useState } from 'react'

import {
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  Text,
  useBreakpoint,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Input } from 'components/Input/Input'
import { Loading } from 'components/Loading'
import { Form, FormikProvider, useFormik } from 'formik'

import logo from '../../assets/images/logo.png'
import { useAuth } from '../../contexts/AuthContext/AuthContext'
import { schema } from './Login.constants'
import { IFormValues } from './Login.interface'

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const { login } = useAuth()
  const onSubmit = async (value: IFormValues) => {
    setIsLoading(true)
    try {
      login(value.user, value.password)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      user: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit,
  })

  const formSize = useBreakpointValue({
    base: '95vw',
    sm: 500,
    md: 600,
  })

  return (
    <>
      <Loading isLoading={isLoading} />
      <Flex
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
        w="100%"
        h="100vh"
      >
        <Flex p="6" flexDirection="column" alignItems={'center'} w={formSize}>
          <Image w={200} src={logo} marginBottom="16" />
          <Heading fontSize="3xl">Faça seu login na plataforma</Heading>
        </Flex>
        <Box p="6" height="50vh" w={formSize} maxH="400px">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Flex gap="8" flexDirection="column">
                <Input
                  value={formik.values.user}
                  label={'Usuario'}
                  name={'user'}
                  placeholder="Digite seu email"
                  errorBorderColor=""
                />
                <Input
                  value={formik.values.password}
                  label={'Senha'}
                  name={'password'}
                  placeholder="Digite sua senha"
                  type="password"
                />

                <Button text="Entrar" type="submit" w="100%" />
              </Flex>
            </Form>
          </FormikProvider>
        </Box>
      </Flex>
    </>
  )
}

export default Login
