import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'

import { Box, Flex, useToast, Button as ButtonChakra } from '@chakra-ui/react'
import axios from 'axios'
import { Header } from 'components/Header'
import { Loading } from 'components/Loading'
import SearchInput from 'components/SearchInput'
import Table from 'components/Table'

import { apiWS } from '../../services'
import { removeEspecialCharacter } from '../../utils/removeEspecialCharacter'
import { headers } from './GruposOfPoints.constants'
import { IGroupOfProducts } from './GrupsOfProducts.interface'

const GroupsOfProducts = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [groupsProducts, setGrupsProducts] = useState<IGroupOfProducts[]>([])
  const [discretionFilter, setDiscretionFilter] = useState('')
  const [filteredGroupsOfProducts, setFilteredGroupsOfProducts] = useState<
    IGroupOfProducts[]
  >([])

  const toast = useToast()

  const getGroupsOfProducts = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IGroupOfProducts[]>(
        '/WSGetGrupoProdutos'
      )
      setGrupsProducts(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const formatProducts = (groupsProducts: IGroupOfProducts[]) => {
    return groupsProducts.map((product) => {
      return {
        classificacao: product.classificacao,
        tipo: product.tipo,
        tipodescricao: product.tipodescricao,
        descricao: product.descricao,
      }
    })
  }

  useEffect(() => {
    getGroupsOfProducts()
  }, [])

  useEffect(() => {
    if (discretionFilter !== '') {
      setFilteredGroupsOfProducts(
        groupsProducts.filter((item) =>
          item.descricao.toLowerCase().includes(discretionFilter.toLowerCase())
        )
      )
    } else {
      setFilteredGroupsOfProducts([])
    }
  }, [discretionFilter, groupsProducts])

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <Box px="1.5rem">
        <Flex justifyContent="end" alignItems={'center'} gap="2rem">
          <SearchInput
            text="Buscar por descrição"
            onChange={(e) => {
              setDiscretionFilter(removeEspecialCharacter(e.target.value))
            }}
            value={discretionFilter}
            rightElement={
              discretionFilter && (
                <ButtonChakra
                  color="gray"
                  _hover={{
                    color: 'red',
                  }}
                  colorScheme={'invariant'}
                  size=""
                  _focus={{
                    boxShadow: 'none',
                  }}
                  onClick={() => setDiscretionFilter('')}
                >
                  <AiOutlineClose />
                </ButtonChakra>
              )
            }
          />
        </Flex>
        <Flex>
          <Table
            headers={headers}
            data={
              filteredGroupsOfProducts.length > 0
                ? formatProducts(filteredGroupsOfProducts)
                : formatProducts(groupsProducts)
            }
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default GroupsOfProducts
