import { Box } from '@chakra-ui/react'

import { ITagProps } from './Tag.interface'

const Tag = ({ isActive }: ITagProps) => {
  return (
    <Box
      borderRadius="30px"
      bg={isActive ? 'green.500' : 'red.400'}
      width="60px"
      minWidth="60px"
      height="20px"
      fontSize="sm"
      color={isActive ? 'white' : 'grey.400'}
      textAlign="center"
      userSelect="none"
      textColor={'#fff'}
    >
      {isActive ? 'Ativo' : 'Inativo'}
    </Box>
  )
}

export default Tag
