import { FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Flex, Heading, HStack } from '@chakra-ui/layout'
import { useToast, VStack } from '@chakra-ui/react'
import Button from 'components/Button'
import Select from 'components/Select'
import Table, { IHeaders } from 'components/Table'

import { IAddProductsBenefitsForm } from '../BenefitsForm.interface'

const productsHeaders: IHeaders[] = [
  {
    title: 'DESCRIÇÃO',
    key: 'descricao',
  },
  {
    title: 'CLASSIFICAÇÃO',
    key: 'classificacao',
  },
  {
    title: 'OPÇÕES',
    key: 'opcao',
    align: 'center',
  },
]

const AddProductsBenefitsForm = ({
  formik,
  isEdit,
  productsOptions,
  selectedProduct,
  productsTable,
  setSelectedProduct,
  setProductsOptions,
  products,
}: IAddProductsBenefitsForm) => {
  const navigate = useNavigate()
  const toast = useToast()

  const handleCancel = () => {
    formik.resetForm()
    navigate('/beneficios')
  }

  const addItens = () => {
    if (selectedProduct === '')
      return toast({
        title: 'Por favor vinculo pelo menos um grupo de produto.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })

    const newOptions = productsOptions.filter(
      (option) => option.value !== selectedProduct
    )

    setProductsOptions(newOptions)

    const product = products?.find((product) => product.uid === selectedProduct)
    if (product) {
      formik.setFieldValue('itensvinculados', [
        ...formik.values.itensvinculados,
        product,
      ])

      setSelectedProduct('')
    } else {
      return toast({
        title: 'grupo de produto não encontrado.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Flex gap={'2rem'} flexDirection={'column'} background="white">
      <VStack>
        <Heading fontWeight={'semibold'} size={'md'} alignSelf={'start'}>
          Adicionar Produtos
        </Heading>
        <HStack spacing={'8'} width={'100%'} alignItems={'flex-start'}>
          <VStack width={'50%'} alignContent={'flex-start'}>
            <Select
              noFormik
              label={'Produto'}
              options={productsOptions}
              placeholder="Selecione um produto"
              handleChange={(e) => setSelectedProduct(e)}
              width={'100%'}
              isDisabled={!productsOptions.length || isEdit}
              variant={'filled'}
              value={selectedProduct}
              name={'selectedProduct'}
            />
            <Button
              text={<FaPlus />}
              width={'100%'}
              alignSelf={'end'}
              onClick={addItens}
              isDisabled={selectedProduct === ''}
            />
          </VStack>
          <VStack width={'50%'}>
            <Flex width={'100%'}>
              <Table headers={productsHeaders} data={productsTable} />
            </Flex>
          </VStack>
        </HStack>
      </VStack>

      <HStack justifyContent={'flex-end'}>
        <Button
          color="gray"
          text="Cancelar"
          minWidth={150}
          onClick={handleCancel}
        />
        <Button
          text={isEdit ? 'Salvar' : 'Cadastrar'}
          minWidth={150}
          type="submit"
          bg={'green.500'}
          _hover={{
            backgroundColor: 'green.700',
          }}
        />
      </HStack>
    </Flex>
  )
}

export default AddProductsBenefitsForm
