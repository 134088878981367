import { Route, Routes } from 'react-router-dom'

import Benefits from './Benefits'
import BenefitsForm from './BenefitsForm'
import Dashboard from './Dashboard'
import GroupsOfClients from './GroupsOfClients'
import GroupsOfProducts from './GroupsOfProducts'
import Login from './Login'
import Notification from './Notification'
import PointsForm from './PointsForm'
import PointsOfSale from './PointsOfSale'
import PrivateRoute from './privateRoute'
import Products from './Products'
import UserApp from './UserApp'

interface IRoute {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: any
  path: string
  title?: string
}

const publicRoutes: IRoute[] = [
  {
    Component: Login,
    path: '/login',
  },
]

const privateRoute: IRoute[] = [
  {
    Component: Dashboard,
    path: '/dashboard',
    title: 'Dashboard',
  },
  {
    Component: PointsOfSale,
    path: '/pontos-de-venda',
    title: 'Pontos de Venda',
  },
  {
    Component: PointsForm,
    path: '/ponto-de-venda/:id',
    title: 'Editar Ponto de Venda',
  },
  {
    Component: GroupsOfProducts,
    path: '/grupo-de-produtos',
    title: 'Grupos de Produtos',
  },
  {
    Component: Benefits,
    path: '/beneficios',
    title: 'Regras de Pontuação, Descontos e Cashback',
  },
  {
    Component: BenefitsForm,
    path: '/novo-beneficio',
    title: 'Cadastrar Benefício',
  },
  {
    Component: BenefitsForm,
    path: '/beneficio/:id',
    title: 'Editar Benefício',
  },
  {
    Component: PointsForm,
    path: '/novo-ponto-de-venda',
    title: 'Grupos de Produtos',
  },
  {
    Component: GroupsOfClients,
    path: '/grupos-de-clientes',
    title: 'Grupos de Produtos',
  },
  {
    Component: UserApp,
    path: '/usuarios-do-app',
    title: 'Grupos de Produtos',
  },
  {
    Component: Products,
    path: '/produtos/:id',
    title: 'Grupos de Produtos',
  },
  {
    Component: Products,
    path: '/produtos',
    title: 'Grupos de Produtos',
  },
  {
    Component: Notification,
    path: '/notificacoes',
    title: 'Grupos de Produtos',
  },
]

const AppRoutes = () => {
  return (
    <Routes>
      {publicRoutes.map(({ Component, path }, idx) => {
        return <Route key={idx} path={path} element={<Component />} />
      })}
      {privateRoute.map(({ Component, path, title }, idx) => {
        return (
          <Route
            key={idx}
            path={path}
            element={<PrivateRoute component={Component} title={title} />}
          />
        )
      })}
    </Routes>
  )
}

export default AppRoutes
