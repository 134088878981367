import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Flex, useToast, Box } from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Header } from 'components/Header'
import { Loading } from 'components/Loading'
import Select from 'components/Select'
import Table, { IHeaders } from 'components/Table'
import { Form, FormikProvider, FormikValues, useFormik } from 'formik'

import { IOptions } from '../../@types/shared'
import { apiWS } from '../../services'
import formatMoney from '../../utils/formatMoney'
import { IPointOfSale } from '../PointsOfSale/PointsOdSale.interface'
import BenefitsModal from './components/BenefitsModal'
import EditModal from './components/EditModal'
import { IProducts } from './Products.interface'

const headers: IHeaders[] = [
  { title: 'STATUS', key: 'ativo' },
  { title: 'DESCRIÇÃO', key: 'descricao' },
  { title: 'CÓDIGO DE BARRAS', key: 'gtin' },
  { title: 'CÓDIGO INTEGRAÇÂO', key: 'idintegracao' },
  { title: 'CÓDIGO ANP', key: 'codigoanp' },
  { title: 'CÓDIGO NCM', key: 'codigoncm' },
  { title: 'PREÇO', key: 'precounitario' },
  { title: ' ', key: 'edit' },
  { title: ' ', key: 'beneficios' },
]

const Products = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState<IProducts[]>([])
  const [pointsOfSaleOptions, setPointsOfSaleOptions] = useState<IOptions[]>([])
  const [isOpenBenefitsModal, setIsOpenBenefitsModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [selectedBenefit, setSelectedBenefit] = useState<number>()
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null)

  const toast = useToast()

  const { id } = useParams()

  const getPointsOfSale = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IPointOfSale[]>('/WSGetPontosVenda')

      setPointsOfSaleOptions(
        data.map((point) => {
          return {
            label: point.razaosocial,
            value: point.id,
          }
        })
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getPointsOfSale()
  }, [])

  const handleOpenBenefitsModal = (idx: number) => {
    setIsOpenBenefitsModal(true)
    setSelectedBenefit(idx)
  }

  const handleOpenEditModal = (product: number) => {
    setIsOpenEditModal(true)
    setSelectedProduct(product)
  }

  const getProducts = async (pointId: number) => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IProducts[]>('/WSGetPVProdutos', {
        pontovendaid: pointId,
      })

      data.shift()
      setProducts(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }
  const onSubmit = ({ pointOfSale }: FormikValues) => {
    getProducts(parseInt(pointOfSale))
  }

  const formik = useFormik({
    initialValues: { pointOfSale: '' },
    onSubmit,
  })

  useEffect(() => {
    if (pointsOfSaleOptions.length) {
      const value = id ? id : pointsOfSaleOptions[0].value
      formik.setFieldValue('pointOfSale', value)
    }
  }, [pointsOfSaleOptions, id])

  useEffect(() => {
    formik.handleSubmit()
  }, [formik.values])

  const handleCloseEditModal = () => {
    getProducts(parseInt(formik.values.pointOfSale))
    setIsOpenEditModal(false)
    setSelectedProduct(null)
  }

  const handleCloseBenefitsModal = () => {
    getProducts(parseInt(formik.values.pointOfSale))
    setIsOpenBenefitsModal(false)
    setSelectedBenefit(undefined)
  }

  const formatData = (data: IProducts[]) => {
    return data.map((product: IProducts, idx) => {
      return {
        ativo: (
          <Box
            borderRadius="30px"
            bg={product.ativo ? 'green.500' : 'grey.400'}
            width="60px"
            minWidth="60px"
            height="20px"
            fontSize="sm"
            color={product.ativo ? 'grey.400' : 'white'}
            textAlign="center"
            userSelect="none"
            textColor={'#fff'}
          >
            {product.ativo ? 'Ativo' : 'Inativo'}
          </Box>
        ),
        descricao: product.produtodescricao,
        codigoanp: product.codigoanp,
        codigoncm: product.codigoncm,
        gtin: product.gtin ? product.gtin : '-',
        idintegracao: product.idintegracao,
        precounitario: formatMoney(product.precounitario),
        beneficios: (
          <Flex justifyContent={'center'}>
            <Button
              text="Benefícios"
              onClick={() => handleOpenBenefitsModal(idx)}
              variant="small"
              width="100px"
            />
          </Flex>
        ),
        edit: (
          <Flex justifyContent={'center'}>
            <Button
              text="Editar"
              onClick={() => handleOpenEditModal(idx)}
              variant="small"
              width="100px"
            />
          </Flex>
        ),
      }
    })
  }

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <Box px="1.5rem">
        {pointsOfSaleOptions.length > 1 && (
          <Flex>
            <FormikProvider value={formik}>
              <Form>
                <Select
                  name="pointOfSale"
                  options={pointsOfSaleOptions}
                  placeholder={'Selecione o ponto de vendas.'}
                  label={'Ponto de vendas'}
                />
              </Form>
            </FormikProvider>
          </Flex>
        )}
        <Flex>
          <Table headers={headers} data={formatData(products)} />
        </Flex>
      </Box>
      {selectedBenefit !== undefined && (
        <BenefitsModal
          benefits={products[selectedBenefit].beneficios}
          isOpen={isOpenBenefitsModal}
          onClose={handleCloseBenefitsModal}
          pointId={parseInt(formik.values.pointOfSale)}
        />
      )}
      {selectedProduct !== null && (
        <EditModal
          isOpen={isOpenEditModal}
          onClose={handleCloseEditModal}
          product={products[selectedProduct]}
          pointId={parseInt(formik.values.pointOfSale)}
        />
      )}
    </Box>
  )
}

export default Products
