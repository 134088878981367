export const headers = [
  { title: 'STATUS', key: 'ativo' },
  { title: 'RAZÃO SOCIAL', key: 'razaosocial' },
  { title: 'CNPJ', key: 'cnpj' },
  { title: 'UF', key: 'uf' },
  { title: 'CIDADE', key: 'cidadenome' },
  { title: 'ENDEREÇO', key: 'logradouro' },
  { title: 'PRODUTOS', key: 'products' },
  { title: 'OPÇÕES', key: 'options' },
]

export const headersProductsOfPointsOfSale = [
  { title: 'STATUS', key: 'ativo' },
  { title: 'DESCRIÇÃO', key: 'descricao' },
  { title: 'CÓDIGO ANP', key: 'codigoanp' },
  { title: 'CÓDIGO NCM', key: 'codigoncm' },
  { title: 'PREÇO', key: 'precounitario' },
]
