import { IHeaders } from 'components/Table'

export const headers: IHeaders[] = [
  {
    title: 'NOME',
    key: 'name',
  },
  {
    title: 'STATUS',
    key: 'ativa',
  },
  {
    title: 'GRUPO DE CLIENTE',
    key: 'grupoclientedescricao',
  },
  {
    title: 'GRUPO DE USUÁRIO',
    key: 'grupousuariodescricao',
  },
  {
    title: 'CPF NA NOTA',
    key: 'cpfnanota',
    align: 'center',
  },
  {
    title: 'EMITIR DOCUMENTO FISCAL',
    key: 'emitirdocumentofiscal',
    align: 'center',
  },
  {
    title: 'OPÇÕES',
    key: 'options',
    align: 'center',
  },
]
