import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useToast } from '@chakra-ui/react'

import { apiWS } from '../../services'

interface IAuthContext {
  token?: string
  user?: string
  login(user: string, password: string): void
  logout(): void
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

export const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string>(() => {
    const localToken = window.localStorage.getItem('accessToken')
    if (localToken) {
      return JSON.parse(localToken)
    }
  })
  const [user, setUser] = useState<string>(() => {
    const username = window.localStorage.getItem('user')
    if (username) {
      return JSON.parse(username)
    }
  })

  const toast = useToast()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const login = async (user: string, password: string) => {
    const payload = { nome: user, senha: password }

    const { data } = await apiWS.post('WSAccountLogin', payload)

    if (!data.logintoken) {
      return toast({
        title: 'Verifique seu usuario ou senha',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }

    window.localStorage.setItem('user', JSON.stringify(data.loginname))
    window.localStorage.setItem('accessToken', JSON.stringify(data.logintoken))

    apiWS.interceptors.request.use((config) => {
      config.headers = {
        logintoken: data.logintoken,
      }

      return config
    })
    setToken(data.logintoken)
    setUser(data.loginname)
    navigate('/dashboard')
  }

  const logout = () => {
    window.localStorage.removeItem('accessToken')
    navigate('/login')
  }

  apiWS.interceptors.request.use((config) => {
    if (config.headers && token && !config.headers?.logintoken)
      config.headers.logintoken = token
    return config
  })

  useEffect(() => {
    if ((token && pathname === '/login') || pathname === '/')
      navigate('/dashboard')
  }, [token])

  return (
    <AuthContext.Provider value={{ login, token, logout, user }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
