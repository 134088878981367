import { useEffect, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Divider } from '@chakra-ui/layout'
import { Flex, useToast } from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Loading } from 'components/Loading'
import { IData } from 'components/Table'
import { useFormik, Form, FormikProvider } from 'formik'
import { formatDate } from 'utils/formatDate'
import formatMoney from 'utils/formatMoney'

import { apiWS } from '../../services'
import RemoveNumberMask from '../../utils/removeNumberMask'
import { schema } from './Benefits.constantes'
import {
  IBenefitsData,
  IBenefitsForm,
  IBenefitsPostResponse,
  IGroupClients,
  IGroupOfProducts,
  IItens,
  IOptions,
} from './BenefitsForm.interface'
import AddProductsBenefitsForm from './components/AddProductsBenefitsForm'
import CashBackBenefitsForm from './components/CachBackBenefitisForm'
import DescBenefitsForm from './components/DescBenefitsForm'
import InitialBenefitsForms from './components/InitialBenefitsForms'
import PointsBenefitsForm from './components/PointsBenefitsForm'

const BenefitsForm: React.FC = () => {
  const [isEdit, setIsEdit] = useState(false)
  const [groupOfClients, setGroupOfClients] = useState<IGroupClients[]>([])
  const [productsOptions, setProductsOptions] = useState<IOptions[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [GroupOfClientsOptions, setGroupOfClientsOptions] =
    useState<IOptions[]>()
  const [selectedProduct, setSelectedProduct] = useState('')
  const [products, setProducts] = useState<IGroupOfProducts[]>([])
  const [productsTable, setProductsTable] = useState<IData[]>([])

  const toast = useToast()
  const params = useParams()
  const navigate = useNavigate()

  const onSubmit = async (values: IBenefitsForm) => {
    setIsLoading(true)
    try {
      if (!values.itensvinculados.length) {
        return toast({
          title: 'É necessario vincular pelo menos 1 produto.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }

      const groupOfClientDescription =
        groupOfClients.find(
          (group) => group.id === parseInt(values.grupoclienteid)
        )?.descricao || ''

      const data: IBenefitsData = {
        updatekind: 1,
        id: isEdit ? parseInt(params.id || '0') : 0,
        grupoclienteid: parseInt(values.grupoclienteid),
        grupoclientesdescricao: groupOfClientDescription,
        descricao: values.descricao,
        isauferirpontosenabled: values.isauferirpontosenabled,
        referencia: values.referencia,
        proporcao: parseInt(RemoveNumberMask(values.proporcao)) || 0,
        auferirpontos: parseInt(values.auferirpontos) || 0,
        vigenciainicial: values.vigenciainicial,
        vigenciafinal: values.vigenciafinal,
        validadepontos: parseInt(RemoveNumberMask(values.validadepontos)) || 0,
        desprezarfracao: values.desprezarfracao,
        isconcederdescontoenabled: values.isconcederdescontoenabled,
        referenciadesconto: values.referenciadesconto,
        auferirdesconto:
          parseInt(RemoveNumberMask(values.auferirdesconto)) || 0,
        isvalorcashbackenabled: values.isvalorcashbackenabled,
        referenciacashback: values.referenciacashback,
        auferircashback:
          parseInt(RemoveNumberMask(values.auferircashback)) || 0,
        ativo: values.ativo,
        itensvinculados: values.itensvinculados,
      }

      const {
        data: { httpstatus },
      } = await apiWS.post<IBenefitsPostResponse>('WSAddBeneficio', data)

      if (httpstatus === 200) {
        toast({
          title: isEdit
            ? 'Dados alterados com sucesso'
            : 'Beneficio cadastrado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        return navigate('/beneficios')
      }
      return toast({
        title: 'Ocorreu um erro ao processar sua requisiçao.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      grupoclienteid: '',
      grupoclientesdescricao: '',
      descricao: '',
      isauferirpontosenabled: true,
      referencia: '',
      proporcao: '',
      auferirpontos: '',
      vigenciainicial: formatDate(new Date().toLocaleDateString()),
      vigenciafinal: '9999-12-31',
      validadepontos: '',
      desprezarfracao: false,
      isconcederdescontoenabled: false,
      referenciadesconto: '',
      auferirdesconto: '',
      isvalorcashbackenabled: false,
      referenciacashback: '',
      auferircashback: '',
      ativo: true,
      itensvinculados: [] as IItens[],
    },
    validationSchema: schema,
    onSubmit,
  })

  const handleGroupOfClients = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IGroupClients[]>('/WSGetGrupoClientes')

      setGroupOfClients(data)

      if (!data.length) {
        return toast({
          title:
            'Este ponto de vendas não tem nenhum grupo de cliente. Por favor escolha um novo ponto de venda ou cadastre um grupo de cliente para este.',
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      }

      setGroupOfClientsOptions(
        data.map((group) => {
          return {
            label: group.descricao,
            value: group.id,
          }
        })
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleProducts = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IGroupOfProducts[]>(
        '/WSGetGrupoProdutos'
      )

      setProducts(data)

      const products = data.filter((group) => {
        if (group.tipo === 'P') {
          return group
        }
      })

      const options = products.map((product) => {
        return {
          label: product.descricao,
          value: product.uid,
        }
      })

      if (options.length) {
        setProductsOptions(options)
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getBenefit = async (id: string) => {
    setIsLoading(true)

    try {
      const { data } = await apiWS.post<IBenefitsData[]>('/WSGetBeneficios')

      const [benefit] = data.filter((benefit) => benefit.id === parseInt(id))

      const {
        ativo,
        auferircashback,
        auferirdesconto,
        auferirpontos,
        descricao,
        desprezarfracao,
        grupoclientesdescricao,
        grupoclienteid,
        isauferirpontosenabled,
        isconcederdescontoenabled,
        isvalorcashbackenabled,
        itensvinculados,
        proporcao,
        referencia,
        referenciacashback,
        referenciadesconto,
        validadepontos,
        vigenciafinal,
        vigenciainicial,
      } = benefit

      const cashBack =
        referenciacashback === 'V'
          ? formatMoney(auferircashback)
          : auferircashback
      const discount =
        referenciadesconto === 'V'
          ? formatMoney(auferirdesconto)
          : auferirdesconto

      formik.setValues({
        ativo,
        auferircashback: String(cashBack),
        auferirdesconto: String(discount),
        auferirpontos: String(auferirpontos),
        descricao,
        desprezarfracao,
        grupoclientesdescricao,
        grupoclienteid: String(grupoclienteid),
        isauferirpontosenabled,
        isconcederdescontoenabled,
        isvalorcashbackenabled,
        itensvinculados: itensvinculados.length
          ? itensvinculados
          : ([] as IItens[]),
        proporcao: String(proporcao),
        referencia,
        referenciacashback,
        referenciadesconto,
        validadepontos: String(validadepontos),
        vigenciafinal,
        vigenciainicial,
      })

      handleGroupOfClients()
      handleProducts()
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (params.id) {
      setIsEdit(true)
      getBenefit(params.id)
    }
  }, [params.id])

  useEffect(() => {
    handleGroupOfClients()
    handleProducts()
  }, [])

  const removeItem = (removeUid: string) => {
    formik.setFieldValue(
      'itensvinculados',
      formik.values.itensvinculados.filter((item) => item.uid !== removeUid)
    )
    const newproduct = products
      .filter((item) => {
        if (item.uid === removeUid) {
          return item
        }
      })
      .map((product) => {
        return {
          label: product.descricao,
          value: product.uid,
        }
      })

    setProductsOptions([...newproduct, ...productsOptions])
  }

  useEffect(() => {
    const itens = formik.values.itensvinculados

    const dataTable = itens.map((item) => {
      return {
        descricao: item.descricao,
        classificacao: item.classificacao,
        opcao: (
          <Flex justifyContent={'center'}>
            <Button
              text={<FaTrashAlt />}
              color="gray"
              width={'18'}
              onClick={() => removeItem(item.uid)}
              alignSelf={'end'}
              isDisabled={isEdit}
            />
          </Flex>
        ),
      }
    })

    setProductsTable(dataTable)
  }, [formik.values.itensvinculados])

  return (
    <Box>
      <Box>
        <Loading isLoading={isLoading} />
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Flex
              gap={'2rem'}
              flexDirection={'column'}
              maxWidth={'96%'}
              margin={'0 auto'}
              boxShadow={'0px 3.45362px 34.5362px rgba(170, 170, 170, 0.25);'}
              padding={'8'}
              background="white"
            >
              <InitialBenefitsForms
                formik={formik}
                isEdit={isEdit}
                GroupOfClientsOptions={GroupOfClientsOptions}
              />

              <Divider />
              <PointsBenefitsForm formik={formik} isEdit={isEdit} />

              <Divider />
              <DescBenefitsForm formik={formik} isEdit={isEdit} />

              <Divider />
              <CashBackBenefitsForm formik={formik} isEdit={isEdit} />

              <Divider />
              <AddProductsBenefitsForm
                formik={formik}
                isEdit={isEdit}
                productsOptions={productsOptions}
                selectedProduct={selectedProduct}
                productsTable={productsTable}
                setSelectedProduct={setSelectedProduct}
                setProductsOptions={setProductsOptions}
                products={products}
              />
            </Flex>
          </Form>
        </FormikProvider>
      </Box>
    </Box>
  )
}

export default BenefitsForm
