import { ReactNode } from 'react'

import { Button as ChakraButton, ButtonProps, Spinner } from '@chakra-ui/react'

interface IProps extends ButtonProps {
  text: string | ReactNode
  onClick?: () => void
  variant?: string
  color?: 'gray' | 'green'
  size?: 'sm' | 'md' | 'xl'
  loading?: boolean
}

const Button = ({
  text,
  onClick,
  variant = 'default',
  color = 'green',
  size = 'md',
  loading,
  ...rest
}: IProps) => {
  const { width } = rest
  const contrastColor = {
    green: '#fff',
    gray: '#868E96',
  }
  const sizes = {
    sm: '150px',
    md: '220px',
    xl: '300px',
  }
  return (
    <ChakraButton
      colorScheme={color}
      width={width || sizes[size]}
      height={variant === 'small' ? 'minmax(20px, 26px)' : '48px'}
      p={variant === 'small' ? '5px 15px' : '13px 25px'}
      onClick={onClick}
      fontSize={variant === 'small' ? 'sm' : 'md'}
      color={contrastColor[color]}
      _focus={{
        boxShadow: 'none',
      }}
      {...rest}
    >
      {loading ? <Spinner /> : text}
    </ChakraButton>
  )
}

export default Button
