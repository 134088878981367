import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai'

import {
  Avatar,
  Box,
  useColorModeValue,
  Flex,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  Button,
  VStack,
  HStack,
} from '@chakra-ui/react'
import { useMenu } from 'contexts/MenuContext/MenuContext'

import { useAuth } from '../../contexts/AuthContext/AuthContext'

interface IProps {
  title: string
}

export const Header = ({ title }: IProps) => {
  const { user, logout } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenMenu, onToggle } = useMenu()

  return (
    <Flex
      justifyContent="space-between"
      p="5%"
      py="1.5rem"
      boxShadow="0px 4px 40px -5px rgba(194, 194, 194, 0.40)"
      position="sticky"
      top="0"
      background="white"
      zIndex="5"
      height="4.875rem"
    >
      <Button onClick={onToggle}>
        {isOpenMenu ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
      </Button>
      <Text
        width="90%"
        textAlign="center"
        as="h1"
        fontWeight="bold"
        fontSize="lg"
      >
        {title}
      </Text>
      <Menu isOpen={isOpen}>
        <MenuButton onClick={onOpen} display="row">
          <Button gap={3}>
            <Avatar size={'sm'} name={user} />
            <Text display={{ base: 'none', md: 'block' }}>
              {user?.split(' ')[0]}
            </Text>
          </Button>
        </MenuButton>

        <MenuList p={0} onMouseEnter={onOpen} onMouseLeave={onClose}>
          <Flex
            direction="column"
            alignItems="center"
            rounded="md"
            padding="8"
            position="relative"
            bg={useColorModeValue('white', 'gray.700')}
            shadow={{ md: 'base' }}
          >
            <Box
              position="absolute"
              inset="0"
              height="20"
              bg={'grey.400'}
              roundedTop="inherit"
            />
            <Avatar size="xl" color={'gray.200'} name={user} />
            <VStack spacing="1" flex="1">
              <HStack>
                <Text fontWeight="bold">{user}</Text>
              </HStack>
              <Text
                fontSize="sm"
                textAlign="center"
                noOfLines={2}
                color={useColorModeValue('gray.600', 'gray.400')}
              >
                {'bio'}
              </Text>
            </VStack>
            <Button
              mt={3}
              variant="outline"
              color="#FE7E7E"
              borderColor={'#FE7E7E'}
              rounded="full"
              size="sm"
              width="full"
              onClick={logout}
            >
              Sair
            </Button>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  )
}
