import { useState } from 'react'

import { Box, Heading, HStack, useToast, VStack } from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Input } from 'components/Input/Input'
import { Loading } from 'components/Loading'
import { apiCep } from 'services'
import { removeEspecialPontuation } from 'utils/removeEspecialCharacter'

import {
  IInformationOfPointsProps,
  ILocationResponse,
} from '../PointsForm.interface'

const LocationsInformationPoints = ({
  isEdit,
  formik,
  setIsEdit,
}: IInformationOfPointsProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const getLocationData = async () => {
    setIsLoading(true)
    try {
      const {
        data: { bairro, cidade, estado_info, logradouro },
      } = await apiCep.get<ILocationResponse>(
        removeEspecialPontuation(formik.values.cep)
      )

      formik.setValues({
        ufnome: estado_info.nome,
        cep: formik.values.cep,
        cidadenome: cidade,
        ativo: true,
        bairro,
        cnpj: formik.values.cnpj,
        complemento: formik.values.complemento,
        logradouro,
        numero: formik.values.numero,
        razaosocial: formik.values.razaosocial,
        nomefantasia: formik.values.nomefantasia,
        avaliacao: formik.values.avaliacao,
        horariofuncionamento: formik.values.horariofuncionamento,
        latitude: formik.values.latitude,
        longitude: formik.values.longitude,
      })
      setIsEdit(true)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <VStack spacing={'2'} gap={'2rem'}>
        <Heading fontWeight={'semibold'} size={'md'} alignSelf={'start'}>
          Informações de localização
        </Heading>
        <HStack width={'100%'} spacing={'8'}>
          <HStack width={'50%'} alignItems={'flex-end'}>
            <Input
              pr={110}
              label={'Cep'}
              mask={'99.999-999'}
              name={'cep'}
              rightElement={
                <Button
                  variant="small"
                  color="gray"
                  text="Pesquisar"
                  minWidth={100}
                  size="sm"
                  bg={'grey.300'}
                  onClick={getLocationData}
                  isDisabled={isEdit}
                />
              }
            />
          </HStack>
          <HStack width={'50%'}>
            <Input label={'Cidade'} name={'cidadenome'} />
            <Input label={'Estado'} name={'ufnome'} />
          </HStack>
        </HStack>
        <HStack width={'100%'} spacing={'8'}>
          <HStack width={'50%'}>
            <Input label={'Rua'} name={'logradouro'} />
            <Input label={'Numero'} name={'numero'} width={'30%'} />
          </HStack>
          <HStack width={'50%'}>
            <Input label={'Bairro'} name={'bairro'} />
          </HStack>
        </HStack>
        <HStack width={'100%'} spacing={'8'} justifyContent={'flex-end'}>
          <HStack width={'50%'}>
            <Input label={'Complemento'} name={'complemento'} />
          </HStack>
          <HStack width={'50%'}>
            <Input isDisabled label={'Latitude'} name={'latitude'} />
            <Input isDisabled label={'Longitude'} name={'longitude'} />
          </HStack>
        </HStack>
      </VStack>
    </Box>
  )
}

export default LocationsInformationPoints
