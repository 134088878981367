import { useState } from 'react'

import {
  Box,
  Heading,
  HStack,
  SimpleGrid,
  useToast,
  VStack,
} from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Input } from 'components/Input/Input'
import { Loading } from 'components/Loading'
import { apiCnpj, apiWS } from 'services'
import { removeEspecialPontuation } from 'utils/removeEspecialCharacter'

import {
  ICNPJResponse,
  ICNPJApiWSResponse,
  IInformationOfPointsProps,
} from '../PointsForm.interface'

const InformationOfPoints = ({
  isEdit,
  formik,
  setIsEdit,
}: IInformationOfPointsProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const getCompanyData = async () => {
    try {
      const { data } = await apiCnpj.get<ICNPJResponse>(
        removeEspecialPontuation(formik.values.cnpj)
      )

      const {
        error,
        BAIRRO,
        CEP,
        COMPLEMENTO,
        LOGRADOURO,
        MUNICIPIO,
        NUMERO,
        UF,
      } = data

      if (error) {
        return toast({
          title: error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }

      formik.setValues({
        ufnome: UF,
        cep: CEP,
        cidadenome: MUNICIPIO,
        ativo: true,
        bairro: BAIRRO,
        cnpj: formik.values.cnpj,
        complemento: COMPLEMENTO,
        logradouro: LOGRADOURO,
        numero: NUMERO,
        razaosocial: data['RAZAO SOCIAL'],
        nomefantasia: formik.values.nomefantasia,
        avaliacao: formik.values.avaliacao,
        horariofuncionamento: formik.values.horariofuncionamento,
      })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }
  const getCnpjWs = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<ICNPJApiWSResponse[]>('/WSSearchPV', [
        {
          cnpj: removeEspecialPontuation(formik.values.cnpj),
        },
      ])

      if (data.length) {
        const {
          razaosocial,
          nomefantasia,
          cep,
          cidadenome,
          ufnome,
          numero,
          complemento,
          horariofuncionamento,
          logradouro,
          avaliacao,
        } = data[0]

        formik.setValues({
          ufnome: ufnome,
          cep: cep,
          cidadenome: cidadenome,
          ativo: true,
          cnpj: formik.values.cnpj,
          complemento: complemento,
          logradouro: logradouro,
          numero: numero,
          razaosocial: razaosocial,
          nomefantasia: nomefantasia,
          avaliacao: avaliacao,
          horariofuncionamento: horariofuncionamento,
        })
        setIsEdit(true)
      } else {
        getCompanyData()
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <HStack justifyContent={'space-between'} mb="1rem">
        <Heading fontWeight={'semibold'} size={'md'}>
          Informações do ponto
        </Heading>
        <HStack alignItems={'baseline'}>
          <Input label="Avaliação" name={'avaliacao'} isDisabled width={'32'} />
        </HStack>
      </HStack>
      <VStack spacing={'2'} gap={'2rem'}>
        <HStack width={'100%'} spacing={'8'}>
          <HStack alignItems={'flex-end'} width={'50%'}>
            <Input
              pr={110}
              mask={'99.999.999/9999-99'}
              isDisabled={isEdit}
              label={'Cnpj'}
              name={'cnpj'}
              rightElement={
                <Button
                  variant="small"
                  color="gray"
                  text="Pesquisar"
                  minWidth={100}
                  size="sm"
                  bg={'grey.300'}
                  onClick={getCnpjWs}
                  isDisabled={isEdit}
                />
              }
            />
          </HStack>
          <HStack width={'50%'}>
            <Input
              label={'Razão Social'}
              name={'razaosocial'}
              isDisabled={isEdit}
            />
          </HStack>
        </HStack>
        <HStack justifyContent={'space-between'} width={'100%'}>
          <HStack width={'50%'}>
            <Input
              label={'Nome Fantasia'}
              name={'nomefantasia'}
              isDisabled={isEdit}
            />
          </HStack>
          <HStack width={'50%'} justifyContent={'flex-end'}>
            <SimpleGrid>
              <Input
                variant="switch"
                label={'Ponto de vendas ativo?'}
                name={'ativo'}
              />
            </SimpleGrid>
          </HStack>
        </HStack>
      </VStack>
    </Box>
  )
}

export default InformationOfPoints
