import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'

import { Box, Flex, useToast, Button as ButtonChakra } from '@chakra-ui/react'
import axios from 'axios'

import Button from '../../components/Button'
import { Header } from '../../components/Header'
import { Loading } from '../../components/Loading'
import SearchInput from '../../components/SearchInput'
import Table, { IHeaders } from '../../components/Table'
import { apiWS } from '../../services'
import { removeEspecialCharacter } from '../../utils/removeEspecialCharacter'
import CreateNotification from './components/CreateNotification'
import { IFormValues, INotification } from './Notification.interface'

const headers: IHeaders[] = [
  { title: 'TÍTULO', key: 'title' },
  { title: 'DISCRIÇÃO', key: 'discricao' },
  { title: 'DESCRIÇÃO DA CAMPANHA', key: 'descriçãodacampanha' },
  { title: 'PLATAFORMA', key: 'plataforma' },
  { title: 'DATA', key: 'data' },
  { title: 'EDITAR', key: 'editar' },
]

const Notification = () => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpemModal, setIsOpemModal] = useState(false)

  const [isEdit, setIsEdit] = useState(false)
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [discretionFilter, setDiscretionFilter] = useState('')
  const [filteredNotification, setFilteredNotification] = useState<
    INotification[]
  >([])

  const [formInicialValues, setFormInocialValues] = useState<IFormValues>({
    campaigntype: 0,
    platform: 0,
    segmentid: 0,
    title: '',
    body: '',
    pushnotitication: true,
    schedule: '',
  })
  const getNotification = async () => {
    try {
      setIsLoading(true)
      const { data } = await apiWS.post('/WSNotifications', [{}])
      setNotifications(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const formatnotification = (notifications: INotification[]) => {
    return notifications.map((item: INotification, index) => {
      return {
        title: item.title,
        descriçãodacampanha: item.campaigndescription,
        plataforma: item.platformdescription,
        discricao: item.body,
        data: item.created,
        editar: (
          <Flex justifyContent={'center'} w="100%">
            <Button
              text="Editar"
              variant="small"
              width="100px"
              onClick={() => opemEditModal(item)}
            />
          </Flex>
        ),
      }
    })
  }

  const opemEditModal = (item: INotification) => {
    setIsOpemModal(true)
    setIsEdit(true)
    setFormInocialValues({
      campaigntype: item.campaigntype,
      platform: item.platform,
      segmentid: item.segmentid,
      title: item.title,
      body: item.body,
      pushnotitication: item.pushnotitication,
      schedule: item.schedule,
      id: item.id,
    })
  }
  const opemCreateModal = () => {
    setIsOpemModal(true)
  }
  const closeModal = async () => {
    setIsOpemModal(false)
    setIsEdit(false)
    setFormInocialValues({
      campaigntype: 0,
      platform: 0,
      segmentid: 0,
      title: '',
      body: '',
      pushnotitication: true,
      schedule: '',
    })
    await getNotification()
  }

  useEffect(() => {
    getNotification()
  }, [])

  useEffect(() => {
    if (discretionFilter !== '') {
      setFilteredNotification(
        notifications.filter((item: INotification) =>
          item.title.toLowerCase().includes(discretionFilter.toLowerCase())
        )
      )
    } else {
      setFilteredNotification([])
    }
  }, [discretionFilter, notifications])
  return (
    <Box>
      <Loading isLoading={isLoading} />
      <Box px={'1.5rem'}>
        <Flex justifyContent="space-between" alignItems={'center'} gap="2rem">
          <Button
            size="md"
            text="Adicionar notificação"
            alignSelf={'end'}
            onClick={opemCreateModal}
          />
          <SearchInput
            text="Pesquisar por titulo"
            onChange={(e) => {
              setDiscretionFilter(removeEspecialCharacter(e.target.value))
            }}
            value={discretionFilter}
            rightElement={
              discretionFilter && (
                <ButtonChakra
                  color="gray"
                  _hover={{
                    color: 'red',
                  }}
                  colorScheme={'invariant'}
                  size=""
                  _focus={{
                    boxShadow: 'none',
                  }}
                  onClick={() => setDiscretionFilter('')}
                >
                  <AiOutlineClose />
                </ButtonChakra>
              )
            }
          />
        </Flex>
        <Flex>
          <Table
            data={
              filteredNotification.length > 0
                ? formatnotification(filteredNotification)
                : formatnotification(notifications)
            }
            headers={headers}
          />
        </Flex>
      </Box>
      {formInicialValues && (
        <CreateNotification
          isOpen={isOpemModal}
          initialValues={formInicialValues}
          onClose={closeModal}
          isEdit={isEdit}
        />
      )}
    </Box>
  )
}

export default Notification
