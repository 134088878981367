import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select as ChakraSelect,
  SelectProps,
} from '@chakra-ui/react'
import { useField } from 'formik'

interface IOptions {
  label: string
  value: string | number
}

interface IProps extends SelectProps {
  options: IOptions[]
  placeholder?: string
  handleChange?: (event: string) => void
  value?: string | number
  errorMessage?: string
  label?: string
  name: string
  noFormik?: boolean
}

const Select = ({
  options,
  placeholder,
  width = '100%',
  label = '',
  name,
  value,
  handleChange,
  noFormik,
  ...rest
}: IProps) => {
  const [field, meta] = useField(name)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: React.ChangeEvent<any>) => {
    if (noFormik && handleChange) {
      handleChange(e.target.value)
    } else {
      field.onChange(e)
    }
  }

  return (
    <FormControl isInvalid={meta.touched && !!meta.error}>
      <FormLabel minHeight={'24px'}>{label}</FormLabel>
      <ChakraSelect
        height="48px"
        background="white"
        placeholder={placeholder}
        width={width}
        onChange={onChange}
        value={noFormik ? value : field.value}
        name={field.name}
        {...rest}
        _focus={{
          borderColor: 'green.500',
        }}
      >
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </ChakraSelect>

      <FormErrorMessage position="absolute" maxH={'8'}>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  )
}

export default Select
