import {
  Box,
  Table as ChackraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

export interface IData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface IHeaders {
  title: string
  key: string | number
  align?:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start'
}

interface IProps {
  data: IData[]
  headers: IHeaders[]
}

const Table = ({ data, headers }: IProps) => {
  return (
    <Box
      overflowY={'auto'}
      maxH={'80vh'}
      width={'100%'}
      mt="1.5rem"
      borderRadius="3.45px"
      boxShadow="0px 3.45362px 34.5362px rgba(170, 170, 170, 0.25)"
    >
      <ChackraTable variant="striped" color="grey.400" colorScheme="table">
        <Thead bg="grey.300" position={'sticky'} top={0} zIndex={'1'}>
          <Tr>
            {headers.map((item, index) => (
              <Th
                textAlign={item.align}
                key={index}
                fontSize="0.7rem"
                fontWeight="bold"
                lineHeight="23px"
                letterSpacing="0.2px"
                color="grey.400"
                pl="0.8rem"
                pr="0.8rem"
              >
                {item.title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody fontSize="sm">
          {data.map((item, index) => {
            return (
              <Tr key={index}>
                {headers.map((header, idx) => {
                  return (
                    <Td
                      key={`header-${idx}`}
                      height="47px"
                      pl="0.8rem"
                      pr="0.8rem"
                    >
                      {item[header.key]}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
          {data.length === 0 && (
            <Tr>
              <Td>Nenhum dado encontrado</Td>
            </Tr>
          )}
        </Tbody>
      </ChackraTable>
    </Box>
  )
}

export default Table
