import { IHeaders } from 'components/Table'
import { removeEspecialPontuation } from 'utils/removeEspecialCharacter'
import * as yup from 'yup'

export const schema = yup.object().shape({
  cnpj: yup
    .string()
    .test('len', 'CNPJ inválido', (val) => {
      if (val) {
        const len = removeEspecialPontuation(val as string).length
        return len === 14
      }
      return false
    })
    .required('Campo obrigatório.'),
  razaosocial: yup.string().required('Campo obrigatório.'),
  cep: yup
    .string()
    .test('len', 'Cep inválido', (val) => {
      if (val) {
        const len = removeEspecialPontuation(val as string).length
        return len === 8
      }
      return false
    })
    .required('Campo obrigatório.'),
  logradouro: yup.string().required('Campo obrigatório.'),
  numero: yup.string().required('Campo obrigatório.'),
  cidadenome: yup.string().required('Campo obrigatório.'),
  ufnome: yup.string().required('Campo obrigatório.'),
  horariofuncionamento: yup.array().of(
    yup.object().shape({
      horaabertura: yup.string().when(['vintequatrohrs', 'naoabre'], {
        is: false,
        then: (schema) =>
          schema
            .required('Campo obrigatório')
            .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido'),
      }),
      horafechamento: yup.string().when(['vintequatrohrs', 'naoabre'], {
        is: false,
        then: (schema) =>
          schema
            .required('Campo obrigatório')
            .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido'),
      }),
    })
  ),
})

export const weekDays: IHeaders[] = [
  {
    title: 'Segunda-feira',
    key: 1,
  },
  {
    title: 'Terça-feira',
    key: 2,
  },
  {
    title: 'Quarta-feira',
    key: 3,
  },
  {
    title: 'Quinta-feira',
    key: 4,
  },
  {
    title: 'Sexta-feira',
    key: 5,
  },
  {
    title: 'Sábado',
    key: 6,
  },
  {
    title: 'Domingo',
    key: 7,
  },
]
