import { useEffect, useState } from 'react'

import {
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import axios from 'axios'
import Textarea from 'components/Textarea'
import { Form, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import Button from '../../../components/Button'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading'
import Select from '../../../components/Select'
import { apiWS } from '../../../services'
import {
  ICreateNotificationProps,
  IFormValues,
  IGroupOfClients,
  IOptions,
} from '../Notification.interface'

const schema = yup.object().shape({
  campaigntype: yup.number().required('Campo Obrigatório'),
  platform: yup.number().required('Campo Obrigatório'),
  segmentid: yup.number().required('Campo Obrigatório'),
  title: yup.string().required('Campo Obrigatório'),
  body: yup.string().required('Campo Obrigatório'),
  pushnotitication: yup.boolean(),
  schedule: yup.string().required('Campo Obrigatório'),
})

const opitionSelectCampaigntype = [
  { label: 'Notificação Padrão', value: 1 },
  { label: 'Notificação Interna', value: 2 },
]

const opitionSelectPlarform = [
  { label: 'Todas', value: 9 },
  { label: 'Android', value: 1 },
  { label: 'iOS', value: 2 },
]

const CreateNotification = ({
  isOpen,
  onClose,
  initialValues,
  isEdit,
}: ICreateNotificationProps) => {
  const toast = useToast()
  const [optionsSegmantId, setOptionsSegmantId] = useState<IOptions[]>([])
  const [isLoading, setLoading] = useState(false)

  const onSubmit = async (values: IFormValues) => {
    setLoading(true)

    const {
      platform,
      campaigntype,
      segmentid,
      title,
      body,
      pushnotitication,
      schedule,
      id,
    } = values

    const data = schedule.split('T')
    const dataformated = data[0] + ' ' + data[1]

    if (id) {
      try {
        await apiWS.post('/WSAddNotification', {
          platform: platform,
          campaigntype: campaigntype,
          segmentid: segmentid,
          tag: '20200221_teste_inc',
          title: title,
          body: body,
          pushnotitication: pushnotitication,
          schedule: dataformated,
          id: id,
        })
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast({
            title: e.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      } finally {
        setLoading(false)
        onClose()
      }
    } else {
      try {
        await apiWS.post('/WSAddNotification', {
          platform: platform,
          campaigntype: campaigntype,
          segmentid: segmentid,
          tag: '20200221_teste_inc',
          title: title,
          body: body,
          pushnotitication: pushnotitication,
          schedule: dataformated,
        })
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast({
            title: e.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      } finally {
        setLoading(false)
        onClose()
      }
    }
  }

  const getGroupsOfClients = async () => {
    try {
      const { data } = await apiWS.post<IGroupOfClients[]>(
        '/WSGetGrupoClientes'
      )

      setOptionsSegmantId(
        data.map((item) => {
          return {
            label: item.descricao,
            value: item.id,
          }
        })
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit,
  })

  const setvalues = () => {
    if (isEdit) {
      if (initialValues) {
        const {
          platform,
          campaigntype,
          segmentid,
          title,
          body,
          pushnotitication,
          schedule,
          id,
        } = initialValues

        const data = schedule.split(' ')
        const dataformated = data[0] + 'T' + data[1]

        formik.setValues({
          campaigntype: campaigntype,
          platform: platform,
          segmentid: segmentid,
          title: title,
          body: body,
          pushnotitication: pushnotitication,
          schedule: dataformated,
          id: id,
        })
      }
    }
  }

  useEffect(() => {
    setvalues()
  }, [initialValues])
  useEffect(() => {
    getGroupsOfClients()
  }, [])

  const onModalClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <Loading isLoading={isLoading} />
      <ModalOverlay />
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <ModalContent paddingBottom="2rem" maxW="65vw" height={'auto'} p={0}>
            <ModalHeader
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              borderRadius={'8px 8px 0px 0'}
              bg={'grey.300'}
            >
              <Heading
                fontWeight={'semibold'}
                size={'md'}
                lineHeight={'inherit'}
              >
                {isEdit ? 'Editar Notificação' : 'Cadastrar Notificação'}
              </Heading>
              <ModalCloseButton
                position={'relative'}
                top={'auto'}
                left={'auto'}
                _focus={{
                  boxShadow: 'none',
                  bg: '#fff',
                }}
                _hover={{
                  bg: '#fff',
                }}
              />
            </ModalHeader>
            <Flex direction="column">
              <Flex p="0 1rem" gap={4}>
                <Flex p="1rem 0" direction="column" gap={4} w="50%">
                  <Input
                    name={'title'}
                    label="Título"
                    placeholder={'Digite o Titulo'}
                  />
                  <Select
                    name="campaigntype"
                    options={opitionSelectCampaigntype}
                    label="Tipo da Campanha"
                    placeholder="Selecione o Tipo da campanha"
                  />
                  <Select
                    name="segmentid"
                    options={optionsSegmantId}
                    label="Grupo de cliente"
                    placeholder="Selecione o grupo de cliente"
                  />
                  <Select
                    name="platform"
                    options={opitionSelectPlarform}
                    label="Plataforma Notificada"
                    placeholder="Selecione a plataforma notificada"
                  />
                </Flex>
                <Flex p="1rem 0" gap={4} w="50%" direction={'column'}>
                  <Textarea
                    name={'body'}
                    label="Descrição"
                    placeholder="Digite a Descrição"
                  />
                  <Input
                    name={'schedule'}
                    label="Programação"
                    type="datetime-local"
                  />
                  <Input
                    name={'pushnotitication'}
                    label="Habilitar notificações push"
                    variant="switch"
                  />
                </Flex>
              </Flex>
              <Flex p="1rem" justifyContent={'center'} direction={'column'}>
                <Flex w={'100%'} justifyContent="flex-end">
                  <Button
                    text={isEdit ? 'Salvar' : 'Cadastrar'}
                    type="submit"
                    size="sm"
                  />
                </Flex>
              </Flex>
            </Flex>
          </ModalContent>
        </Form>
      </FormikProvider>
    </Modal>
  )
}

export default CreateNotification
