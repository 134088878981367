import { IHeaders } from '../../components/Table'
export const headers: IHeaders[] = [
  {
    title: 'DESCRIÇÃO',
    key: 'descricao',
  },
  {
    title: 'VIGÊNCIA INICIAL',
    key: 'vigenciainicial',
  },
  {
    title: 'VIGÊNCIA FINAL',
    key: 'vigenciafinal',
  },

  {
    title: 'PONTOS',
    key: 'auferirpontos',
    align: 'center',
  },
  {
    title: 'DESCONTO',
    key: 'auferirdesconto',
    align: 'center',
  },
  {
    title: 'CASHBACK',
    key: 'auferircashback',
    align: 'center',
  },
  {
    title: 'OPÇÕES',
    key: 'options',
    align: 'center',
  },
]
