import { Flex, Heading, HStack } from '@chakra-ui/layout'
import { Input } from 'components/Input/Input'
import Select from 'components/Select'

import { IPointsBenefitsFormProps } from '../BenefitsForm.interface'

const PointsBenefitsForm = ({ formik, isEdit }: IPointsBenefitsFormProps) => {
  return (
    <Flex gap={'2rem'} flexDirection={'column'} background="white">
      <HStack width={'100%'}>
        <Heading fontWeight={'semibold'} size={'md'} alignSelf={'start'}>
          Pontos
        </Heading>
        <Input
          nothingLabel={true}
          display="flex"
          alignItems="center"
          variant="switch"
          name={'isauferirpontosenabled'}
        />
      </HStack>
      <HStack width={'100%'} spacing={'8'}>
        <HStack width={'50%'} alignItems={'flex-end'}>
          <Select
            label="Referencia"
            options={[
              { label: 'Valor', value: 'V' },
              { label: 'Quantidade', value: 'Q' },
            ]}
            placeholder="Selecione a referencia"
            width={'100%'}
            isDisabled={!formik.values.isauferirpontosenabled}
            variant={'filled'}
            errorMessage={formik.errors.referencia}
            name={'referencia'}
          />
        </HStack>
        <HStack width={'50%'}>
          <Input
            currency
            precision={formik.values.referencia === 'V' ? '3' : '0'}
            prefix={formik.values.referencia === 'V' ? 'R$ ' : undefined}
            suffix={formik.values.referencia === 'Q' ? ' Litros' : undefined}
            label={'Proporção'}
            name={'proporcao'}
            chakraVariant="filled"
            placeholder="Digite aqui a proporção."
            isDisabled={
              !formik.values.isauferirpontosenabled ||
              isEdit ||
              formik.values.referencia === ''
            }
          />
        </HStack>
      </HStack>
      <HStack width={'100%'} spacing={'8'}>
        <HStack width={'50%'}>
          <Input
            label={'Quantidade de pontos'}
            placeholder="Digite a quantidade de pontos."
            name={'auferirpontos'}
            type={'number'}
            chakraVariant="filled"
            isDisabled={!formik.values.isauferirpontosenabled || isEdit}
          />
        </HStack>
        <HStack width={'50%'}>
          <Input
            currency
            precision="0"
            suffix=" Dias"
            label={'Validade dos pontos'}
            name={'validadepontos'}
            chakraVariant="filled"
            placeholder="Digite aqui a data de validade."
            isDisabled={!formik.values.isauferirpontosenabled || isEdit}
          />
        </HStack>
      </HStack>
      <HStack width={'100%'} spacing={'8'} justifyContent={'flex-start'}>
        <HStack width={'50%'}>
          <Input
            variant="switch"
            label={'Desprezar fração no cálculo'}
            name={'desprezarfracao'}
            isDisabled={!formik.values.isauferirpontosenabled || isEdit}
          />
        </HStack>
      </HStack>
    </Flex>
  )
}

export default PointsBenefitsForm
