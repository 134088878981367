import * as yup from 'yup'

import RemoveNumberMask from '../../utils/removeNumberMask'

export const schema = yup.object().shape({
  grupoclienteid: yup.string().required('Campo obrigatório.'),
  descricao: yup.string().required('Campo obrigatório.'),
  referencia: yup.string().when('isauferirpontosenabled', {
    is: true,
    then: (schema) => schema.required('Campo Obrigatório'),
  }),
  proporcao: yup.string().when('isauferirpontosenabled', {
    is: true,
    then: (schema) =>
      schema
        .test('int', 'Proporção deve ser maior que zero', (val) => {
          if (val) {
            const int = parseFloat(RemoveNumberMask(val))
            return int > 0
          }
          return false
        })
        .required('Campo Obrigatório'),
  }),
  auferirpontos: yup.string().when('isauferirpontosenabled', {
    is: true,
    then: (schema) =>
      schema
        .test('int', 'Quantidade de pontos deve ser maior que zero', (val) => {
          if (val) {
            const int = parseInt(val)
            return int > 0
          }
          return false
        })
        .required('Campo Obrigatório'),
  }),
  vigenciainicial: yup.date().required('Campo obrigatório.'),
  vigenciafinal: yup
    .date()
    .required('Campo obrigatório.')
    .min(
      yup.ref('vigenciainicial'),
      'data final deve ser maior que a data inicial'
    ),
  validadepontos: yup.string().when('isauferirpontosenabled', {
    is: true,
    then: (schema) =>
      schema
        .test('int', 'Validade deve ser maior que zero', (val) => {
          if (val) {
            const int = parseInt(RemoveNumberMask(val))
            return int > 0
          }
          return false
        })
        .required('Campo Obrigatório'),
  }),
  referenciadesconto: yup.string().when('isconcederdescontoenabled', {
    is: true,
    then: (schema) => schema.required('Campo Obrigatório'),
  }),
  auferirdesconto: yup.string().when('isconcederdescontoenabled', {
    is: true,
    then: (schema) =>
      schema
        .test('int', 'Desconto deve ser maior que zero', (val) => {
          if (val) {
            const int = parseFloat(RemoveNumberMask(val))
            return int > 0
          }
          return false
        })
        .required('Campo Obrigatório'),
  }),
  referenciacashback: yup.string().when('isvalorcashbackenabled', {
    is: true,
    then: (schema) => schema.required('Campo Obrigatório'),
  }),
  auferircashback: yup.string().when('isvalorcashbackenabled', {
    is: true,
    then: (schema) =>
      schema
        .test('int', 'Cashback deve ser maior que zero', (val) => {
          if (val) {
            const int = parseFloat(RemoveNumberMask(val))
            return int > 0
          }
          return false
        })
        .required('Campo Obrigatório'),
  }),
})
