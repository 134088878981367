import React, { createContext, useContext } from 'react'

import { useDisclosure } from '@chakra-ui/react'

interface IMenuContext {
  isOpen: boolean
  onClose(): void
  onOpen(): void
  onToggle(): void
}

const MenuContext = createContext<IMenuContext>({} as IMenuContext)

export const MenuProvider: React.FC = ({ children }) => {
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  })

  return (
    <MenuContext.Provider value={{ isOpen, onClose, onOpen, onToggle }}>
      {children}
    </MenuContext.Provider>
  )
}

export const useMenu = () => useContext(MenuContext)
