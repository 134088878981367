import { IHeaders } from 'components/Table'

export const headers: IHeaders[] = [
  {
    title: 'STATUS',
    key: 'ativo',
  },
  {
    title: 'DESCRIÇÃO',
    key: 'descricao',
  },
  {
    title: 'PROTEGIDO',
    key: 'protegido',
    align: 'center',
  },
  {
    title: 'OPÇÕES',
    key: 'options',
    align: 'center',
  },
]
