import { Box, Heading, HStack, VStack } from '@chakra-ui/react'
import { Input } from 'components/Input/Input'
import Table, { IData } from 'components/Table'

import { weekDays } from '../PointsForm.constants'
import { ITimetablePointsProps } from '../PointsForm.interface'

const TimetablePoints = ({ formik }: ITimetablePointsProps) => {
  const timetableForm = (key: number) => {
    return (
      <VStack maxWidth={'200px'} spacing={'6'} minHeight={'300px'}>
        <Input
          name={`horariofuncionamento[${key}].vintequatrohrs`}
          variant="switch"
          displayFlex
          label="24H "
          isDisabled={formik.values.horariofuncionamento[key]?.naoabre}
        />
        <Input
          name={`horariofuncionamento[${key}].naoabre`}
          variant="switch"
          displayFlex
          label="Não Abre "
          isDisabled={formik.values.horariofuncionamento[key]?.vintequatrohrs}
        />
        <Input
          name={`horariofuncionamento[${key}].horaabertura`}
          label="Hora de abertura"
          mask={'99:99'}
          isDisabled={
            formik.values.horariofuncionamento[key]?.naoabre ||
            formik.values.horariofuncionamento[key]?.vintequatrohrs
          }
        />
        <Input
          name={`horariofuncionamento[${key}].horafechamento`}
          label="Hora de fechamento"
          mask={'99:99'}
          isDisabled={
            formik.values.horariofuncionamento[key]?.naoabre ||
            formik.values.horariofuncionamento[key]?.vintequatrohrs
          }
        />
      </VStack>
    )
  }

  const tableData: IData[] = [
    {
      1: timetableForm(0),
      2: timetableForm(1),
      3: timetableForm(2),
      4: timetableForm(3),
      5: timetableForm(4),
      6: timetableForm(5),
      7: timetableForm(6),
    },
  ]

  return (
    <Box>
      <VStack spacing={'2'}>
        <Heading fontWeight={'semibold'} size={'md'} alignSelf={'start'}>
          Horário de funcionamento
        </Heading>
        <HStack width={'100%'} spacing={'8'}>
          <Table headers={weekDays} data={tableData} />
        </HStack>
      </VStack>
    </Box>
  )
}

export default TimetablePoints
