export const removeEspecialPontuation = (str: string) => {
  return str.replace(/[^a-zA-Z0-9s]/g, '').toLowerCase()
}

export const removeEspecialCharacter = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}
