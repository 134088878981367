import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FaCheck, FaTimesCircle } from 'react-icons/fa'

import { Box, Flex, useToast, Button as ButtonChakra } from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Loading } from 'components/Loading'
import SearchInput from 'components/SearchInput'
import Table, { IData } from 'components/Table'
import Tag from 'components/Tag'

import { apiWS } from '../../services'
import { removeEspecialCharacter } from '../../utils/removeEspecialCharacter'
import GroupOfClientsForm from './components/GroupOfClientsForm'
import { headers } from './GroupsOfClients.constants'
import { IGroupOfClients } from './GroupsOfClients.interface'

const GroupsOfClients = () => {
  const [clients, setClients] = useState<IData[]>([])
  const [clientsList, setClientsList] = useState<IGroupOfClients[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isAddClient, setIsAddClient] = useState(false)
  const [discretionFilter, setDiscretionFilter] = useState('')
  const [filteredGroupsOfClients, setFilteredGroupsOfClients] = useState<
    IGroupOfClients[]
  >([])
  const [formInitialValues, setFormInitialValues] = useState<IGroupOfClients>({
    ativo: true,
    descricao: '',
    protegido: true,
    nrousuarios: 0,
  })
  const [isEdit, setIsEdit] = useState(false)

  const toast = useToast()

  const onEdit = (item: IGroupOfClients) => {
    setIsEdit(true)
    setFormInitialValues({
      ativo: item.ativo,
      descricao: item.descricao,
      id: item.id,
      protegido: item.protegido,
      nrousuarios: item.nrousuarios,
    })
    setIsAddClient(true)
  }
  const formatClients = (data: IGroupOfClients[]) => {
    return data.map((client) => {
      return {
        ativo: <Tag isActive={client.ativo} />,
        descricao: client.descricao,
        protegido: client.protegido ? (
          <Flex justifyContent={'center'} w="100%">
            <FaCheck color="green" />
          </Flex>
        ) : (
          <Flex justifyContent={'center'} w="100%">
            <FaTimesCircle color="red" />
          </Flex>
        ),
        options: (
          <Flex justifyContent={'center'} w="100%">
            <Button
              text="Editar"
              onClick={() => onEdit(client)}
              variant="small"
              width="100px"
            />
          </Flex>
        ),
      }
    })
  }
  const getGroupsOfClients = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IGroupOfClients[]>(
        '/WSGetGrupoClientes'
      )

      setClients(formatClients(data))
      setClientsList(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getGroupsOfClients()
  }, [])

  const onCloseModal = () => {
    setIsAddClient(false)
    getGroupsOfClients()
    setIsEdit(false)
  }

  useEffect(() => {
    if (discretionFilter !== '') {
      setFilteredGroupsOfClients(
        clientsList.filter((item) =>
          item.descricao.toLowerCase().includes(discretionFilter.toLowerCase())
        )
      )
    } else {
      setFilteredGroupsOfClients([])
    }
  }, [discretionFilter, clientsList])

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <Box px="1.5rem">
        <Flex justifyContent="space-between" alignItems={'center'} gap="2rem">
          <Button
            size="md"
            text="Adicionar grupo de cliente"
            onClick={() => setIsAddClient(true)}
            alignSelf={'end'}
          />
          <SearchInput
            text="Buscar por descrição"
            onChange={(e) => {
              setDiscretionFilter(removeEspecialCharacter(e.target.value))
            }}
            value={discretionFilter}
            rightElement={
              discretionFilter && (
                <ButtonChakra
                  color="gray"
                  _hover={{
                    color: 'red',
                  }}
                  colorScheme={'invariant'}
                  size=""
                  _focus={{
                    boxShadow: 'none',
                  }}
                  onClick={() => setDiscretionFilter('')}
                >
                  <AiOutlineClose />
                </ButtonChakra>
              )
            }
          />
        </Flex>
        <Flex>
          <Table
            headers={headers}
            data={
              filteredGroupsOfClients.length > 0
                ? formatClients(filteredGroupsOfClients)
                : clients
            }
          />
        </Flex>
      </Box>
      {formInitialValues && (
        <GroupOfClientsForm
          isOpen={isAddClient}
          onClose={onCloseModal}
          initialValues={formInitialValues}
          isEdit={isEdit}
        />
      )}
    </Box>
  )
}
export default GroupsOfClients
