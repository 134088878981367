import React, { useEffect, useState } from 'react'

import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  theme,
} from '@chakra-ui/react'
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from 'recharts'
import formatMoney from 'utils/formatMoney'

import { IBarChartProps, ICardInfo } from './BarChart.interface'

const BarChart = ({ dataKeys, data, xAxisKey, title }: IBarChartProps) => {
  const [key1, key2, key3] = dataKeys
  const [month, setMonth] = useState('')
  const [cardsInfo, setCardsInfo] = useState<ICardInfo>({})

  useEffect(() => {
    if (data.length) {
      const lastData = data[data.length - 1]

      setMonth(lastData.mes)
      setCardsInfo({
        ...lastData,
        [key1]: formatMoney(lastData[key1], 2),
        [key2]: formatMoney(lastData[key2], 2),
      })
    }
  }, [data])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeFocus = (data: any) => {
    setMonth(data.mes)
    setCardsInfo({
      ...data,
      [key1]: formatMoney(data[key1], 2),
      [key2]: formatMoney(data[key2], 2),
    })
  }

  return (
    <Box
      width={{ xs: 'full', xl: '2xl', lg: 'lg' }}
      background={'white'}
      borderRadius={'2xl'}
      py={'5'}
    >
      <Heading as={'h1'} size={'md'} textAlign={'left'} px={'5'}>
        {title}
      </Heading>
      <Box width={'full'} height={'80'} mb={'4'}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={5}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey={xAxisKey} fontFamily="inherit" fontSize={12} />
            <YAxis fontFamily="inherit" fontSize={12} />
            <Tooltip
              content={
                <Box
                  width={'max-content'}
                  background={'white'}
                  borderRadius={'10'}
                  px={'2'}
                  py={'2'}
                  boxShadow="0px 3.45362px 34.5362px rgba(170, 170, 170, 0.25)"
                >
                  <HStack alignItems={'flex-start'} gap={'2'}>
                    <Text fontSize={'md'}>{key1}</Text>
                    <Text fontSize={'lg'} color={theme.colors.green[500]}>
                      {cardsInfo[key1]}
                    </Text>
                  </HStack>
                  <HStack alignItems={'flex-start'} gap={'2'}>
                    <Text fontSize={'md'}>{key2}</Text>
                    <Text fontSize={'lg'} color={theme.colors.green[200]}>
                      {cardsInfo[key2]}
                    </Text>
                  </HStack>
                  <HStack alignItems={'flex-start'} gap={'2'}>
                    <Text fontSize={'md'}>{key3}</Text>
                    <Text fontSize={'lg'} color={'#8884d8'}>
                      {cardsInfo[key3]}
                    </Text>
                  </HStack>
                </Box>
              }
            />
            <Legend
              iconType={'circle'}
              iconSize={10}
              wrapperStyle={{ fontSize: '15px' }}
            />
            <Bar
              dataKey={key2}
              stackId="a"
              fill={theme.colors.green[200]}
              onClick={handleChangeFocus}
              cursor={'pointer'}
            />
            <Bar
              dataKey={key1}
              stackId="a"
              fill={theme.colors.green[500]}
              onClick={handleChangeFocus}
              cursor={'pointer'}
            />
            <Line type="monotone" dataKey={key3} stroke="#8884d8" />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Divider />
      <Box pt={'10'} px={'5'}>
        <Heading as={'h1'} size={'md'} textAlign={'left'}>
          {month}
        </Heading>
        <Flex
          justifyContent={'space-between'}
          py={'5'}
          flexDirection={{ base: 'column', sm: 'row' }}
        >
          <Stack
            alignItems={'flex-start'}
            gap={'2'}
            direction={{ base: 'row', sm: 'column' }}
            w={{ base: 'full', sm: 'auto' }}
            justifyContent={{ base: 'space-between' }}
          >
            <Text fontSize={'md'}>{key1}</Text>
            <Text
              fontSize={'lg'}
              color={theme.colors.green[500]}
              textAlign={'right'}
              width={{ base: 'auto', sm: 'full' }}
            >
              {cardsInfo[key1]}
            </Text>
          </Stack>
          <Stack
            alignItems={'flex-start'}
            gap={'2'}
            direction={{ base: 'row', sm: 'column' }}
            w={{ base: 'full', sm: 'auto' }}
            justifyContent={{ base: 'space-between' }}
          >
            <Text fontSize={'md'}>{key2}</Text>
            <Text
              fontSize={'lg'}
              color={theme.colors.green[200]}
              textAlign={'right'}
              width={{ base: 'auto', sm: 'full' }}
            >
              {cardsInfo[key2]}
            </Text>
          </Stack>
          <Stack
            alignItems={'flex-start'}
            gap={'2'}
            direction={{ base: 'row', sm: 'column' }}
            w={{ base: 'full', sm: 'auto' }}
            justifyContent={{ base: 'space-between' }}
          >
            <Text fontSize={'md'}>{key3}</Text>
            <Text
              fontSize={'lg'}
              color={'#8884d8'}
              textAlign={'right'}
              width={{ base: 'auto', sm: 'full' }}
            >
              {cardsInfo[key3]}
            </Text>
          </Stack>
        </Flex>
      </Box>
    </Box>
  )
}

export default BarChart
