import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FaCheck, FaTimesCircle } from 'react-icons/fa'

import { Box, Flex, useToast, Button as ButtonChakra } from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Header } from 'components/Header'
import { Loading } from 'components/Loading'
import SearchInput from 'components/SearchInput'
import Select from 'components/Select'
import Table from 'components/Table'
import Tag from 'components/Tag'
import UserAppForm from 'components/UsersAppForm'
import { Form, FormikProvider, useFormik } from 'formik'

import { IOptions } from '../../@types/shared'
import { apiWS } from '../../services'
import { removeEspecialCharacter } from '../../utils/removeEspecialCharacter'
import { headers } from './UserApp.constants'
import {
  IGroupOfClients,
  IGroupOfClientsSelect,
  IInitivalues,
  IUser,
} from './UserApp.interface'

const UserApp = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState<IUser[]>([])
  const [isEdit, setIsEdit] = useState(false)
  const [options, setOptions] = useState<IOptions[]>([])

  const [formInitialValues, setFormInitialValues] = useState<IInitivalues>(
    {} as IInitivalues
  )
  const [nameFilter, setNameFilter] = useState('')
  const [filteredUserApp, setFilteredUserApp] = useState<IUser[]>([])

  const toast = useToast()

  const onEdit = (item: IUser) => {
    setFormInitialValues({
      grupoclienteid: item.grupoclienteid,
      ativo: item.ativa,
      nome: item.nome,
    })
    setIsEdit(true)
  }

  const getUser = async (grupOfClientId: number | string) => {
    setIsLoading(true)
    try {
      const headers =
        grupOfClientId === 'all' ? {} : { grupoclienteid: grupOfClientId }
      const { data } = await apiWS.post<IUser[]>('/WSGetAccounts', headers)
      setUsers(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const formatUsersData = (users: IUser[]) => {
    return users.map((user) => {
      return {
        name: user.nome,
        ativa: <Tag isActive={user.ativa} />,
        grupoclienteid: user.grupoclienteid,
        grupoclientedescricao: user.grupoclientedescricao,
        grupousuariodescricao: user.grupousuariodescricao,
        cpfnanota: user.cpfnanota ? (
          <Flex justifyContent={'center'} w="100%">
            <FaCheck color="green" />
          </Flex>
        ) : (
          <Flex justifyContent={'center'} w="100%">
            <FaTimesCircle color="red" />
          </Flex>
        ),
        emitirdocumentofiscal: user.emitirdocumentofiscal ? (
          <Flex justifyContent={'center'} w="100%">
            <FaCheck color="green" />
          </Flex>
        ) : (
          <Flex justifyContent={'center'} w="100%">
            <FaTimesCircle color="red" />
          </Flex>
        ),
        options: (
          <Flex justifyContent={'center'} w="100%">
            <Button
              text="Editar"
              onClick={() => onEdit(user)}
              variant="small"
              width="100px"
            />
          </Flex>
        ),
      }
    })
  }

  const formatOptions = (data: IGroupOfClients[]) => {
    const options = data.map((option) => {
      return {
        label: option.descricao,
        value: option.id,
      }
    })

    setOptions([{ label: 'Todos', value: 'all' }, ...options])
  }

  const getGroupsOfClientsOptions = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IGroupOfClients[]>(
        '/WSGetGrupoClientes'
      )
      formatOptions(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (nameFilter !== '') {
      setFilteredUserApp(
        users.filter((item) =>
          item.nome.toLowerCase().includes(nameFilter.toLowerCase())
        )
      )
    } else {
      setFilteredUserApp([])
    }
  }, [nameFilter, users])

  const onSubmit = (values: IGroupOfClientsSelect) => {
    getUser(values.groupOfClientId)
  }

  const formik = useFormik({
    initialValues: { groupOfClientId: 'all' },
    onSubmit,
  })

  useEffect(() => {
    formik.handleSubmit()
  }, [formik.values])

  const onCloseModal = () => {
    setIsEdit(false)
    getUser(formik.values.groupOfClientId)
  }

  useEffect(() => {
    getGroupsOfClientsOptions()
    getUser(formik.values.groupOfClientId)
  }, [])

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <Box px="1.5rem">
        <Flex justifyContent="space-between" alignItems={'end'} gap="2rem">
          <FormikProvider value={formik}>
            <Form>
              <Select
                name="groupOfClientId"
                options={options}
                label={'Grupo de cliente'}
              />
            </Form>
          </FormikProvider>
          <SearchInput
            text="Buscar por nome"
            onChange={(e) => {
              setNameFilter(removeEspecialCharacter(e.target.value))
            }}
            value={nameFilter}
            rightElement={
              nameFilter && (
                <ButtonChakra
                  color="gray"
                  _hover={{
                    color: 'red',
                  }}
                  colorScheme={'invariant'}
                  size=""
                  _focus={{
                    boxShadow: 'none',
                  }}
                  onClick={() => setNameFilter('')}
                >
                  <AiOutlineClose />
                </ButtonChakra>
              )
            }
          />
        </Flex>
        <Flex pb="3rem">
          <Table
            headers={headers}
            data={
              filteredUserApp.length > 0
                ? formatUsersData(filteredUserApp)
                : formatUsersData(users)
            }
          />
        </Flex>
      </Box>
      <UserAppForm
        options={options}
        onClose={onCloseModal}
        isOpen={isEdit}
        initialValues={formInitialValues}
      />
    </Box>
  )
}

export default UserApp
