import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

import { Box, Flex, useToast, Button as ButtonChakra } from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Header } from 'components/Header'
import { Loading } from 'components/Loading'
import SearchInput from 'components/SearchInput'
import Table from 'components/Table'
import Tag from 'components/Tag'

import { apiWS } from '../../services'
import {
  removeEspecialCharacter,
  removeEspecialPontuation,
} from '../../utils/removeEspecialCharacter'
import { IPointOfSale } from './PointsOdSale.interface'
import { headers } from './PointsOfSale.constants'

const PointsOfSale = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const [filteredPointsOfSale, setFilteredPointsOfSale] = useState<
    IPointOfSale[]
  >([])
  const [filter, setFilter] = useState({ cnpj: '', name: '' })
  const [pointsOfSale, setPointsOfSale] = useState<IPointOfSale[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const getPointsOfSale = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IPointOfSale[]>('/WSGetPontosVenda')

      setPointsOfSale(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onEdit = (points: IPointOfSale) => {
    navigate(`/ponto-de-venda/${points.id}`)
  }

  const formatPointsOfSaleData = (data: IPointOfSale[]) => {
    return data.map((points) => {
      return {
        ativo: <Tag isActive={points.ativo === 1} />,
        razaosocial: points.razaosocial,
        cnpj: points.cnpj,
        uf: points.uf,
        cidadenome: points.cidadenome,
        logradouro: points.logradouro,
        products: (
          <Button
            text="Produtos"
            onClick={() => navigate(`/produtos/${points.id}`)}
            variant="small"
            width="100px"
          />
        ),
        options: (
          <Button
            text="Editar"
            onClick={() => onEdit(points)}
            variant="small"
            width="100px"
          />
        ),
      }
    })
  }

  useEffect(() => {
    getPointsOfSale()
  }, [])

  useEffect(() => {
    if (filter.cnpj !== '' || filter.name !== '') {
      setFilteredPointsOfSale(
        pointsOfSale.filter((item) => {
          return (
            item.cnpj.includes(removeEspecialPontuation(filter.cnpj)) &&
            removeEspecialCharacter(item.razaosocial).includes(
              removeEspecialCharacter(filter.name)
            )
          )
        })
      )
    } else {
      setFilteredPointsOfSale([])
    }
  }, [filter])

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <Box px="1.5rem">
        <Flex gap={3}>
          <Button
            text="Adicionar ponto"
            onClick={() => navigate('/novo-ponto-de-venda')}
            size="md"
          />
          <SearchInput
            text="Buscar por Razão Social"
            onChange={(e) => {
              setFilter({
                ...filter,
                name: removeEspecialCharacter(e.target.value),
              })
            }}
            value={filter.name}
            rightElement={
              filter.name && (
                <ButtonChakra
                  color="gray"
                  _hover={{
                    color: 'red',
                  }}
                  colorScheme={'invariant'}
                  size=""
                  _focus={{
                    boxShadow: 'none',
                  }}
                  onClick={() => setFilter({ ...filter, name: '' })}
                >
                  <AiOutlineClose />
                </ButtonChakra>
              )
            }
          />
          <SearchInput
            text="Buscar por CNPJ"
            onChange={(e) => {
              setFilter({
                ...filter,
                cnpj: removeEspecialPontuation(e.target.value),
              })
            }}
            value={filter.cnpj}
            cnpj
            rightElement={
              filter.cnpj && (
                <ButtonChakra
                  color="gray"
                  _hover={{
                    color: 'red',
                  }}
                  colorScheme={'invariant'}
                  size=""
                  _focus={{
                    boxShadow: 'none',
                  }}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      cnpj: '',
                    })
                  }
                >
                  <AiOutlineClose />
                </ButtonChakra>
              )
            }
          />
        </Flex>
        <Flex pb="3rem">
          <Table
            data={
              filter.name !== '' || filter.cnpj !== ''
                ? formatPointsOfSaleData(filteredPointsOfSale)
                : formatPointsOfSaleData(pointsOfSale)
            }
            headers={headers}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default PointsOfSale
