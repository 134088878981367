import { Navigate } from 'react-router-dom'

import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import { Header } from 'components/Header'
import SideBar from 'components/SideBar'
import { useMenu } from 'contexts/MenuContext/MenuContext'

import { useAuth } from '../contexts/AuthContext/AuthContext'
import { LocationsProvider } from '../contexts/locations'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any
  title?: string
}

const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  title = '',
}) => {
  const { token } = useAuth()
  const { isOpen } = useMenu()

  const paddingLeft = useBreakpointValue({
    base: 0,
    lg: '35vw',
    xl: '24vw',
  })

  if (token) {
    return (
      <LocationsProvider>
        <Flex>
          <SideBar />
        </Flex>
        <Flex
          minH="100vh"
          w={'full'}
          direction={'column'}
          position="relative"
          pl={isOpen ? paddingLeft : ''}
          background="grey.200"
        >
          <Header title={title} />
          <Box paddingTop={'4'}>
            <RouteComponent />
          </Box>
        </Flex>
      </LocationsProvider>
    )
  }

  return <Navigate to="/login" />
}

export default PrivateRoute
