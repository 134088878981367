import * as React from 'react'
import ReactDOM from 'react-dom'

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { App } from './App'
import Providers from './contexts'
import { theme } from './styles/theme'

Sentry.init({
  dsn: 'https://42ea3f2f3efd494a9a7c8688ac249b95@o1163530.ingest.sentry.io/6251757',
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
})

ReactDOM.render(
  <>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <Providers>
        <App />
      </Providers>
    </ChakraProvider>
  </>,
  document.getElementById('root')
)
