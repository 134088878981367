import { AiOutlineMenuFold } from 'react-icons/ai'
import {
  FaTh,
  FaMapMarkerAlt,
  FaShoppingCart,
  FaUserFriends,
  FaPollH,
  FaUserCircle,
  FaArchive,
  FaBell,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Flex, Image, useBreakpointValue } from '@chakra-ui/react'
import { useMenu } from 'contexts/MenuContext/MenuContext'

import logo from '../../assets/images/logo.png'
import NavItem from './navItem'

const SideBar = () => {
  const { isOpen, onClose } = useMenu()
  const navigate = useNavigate()

  const closeButton = useBreakpointValue({
    base: (
      <Button onClick={onClose} position={'absolute'} right={10}>
        <AiOutlineMenuFold />
      </Button>
    ),
    lg: <></>,
  })

  const width = useBreakpointValue({
    base: '100vw',
    lg: '35vw',
    xl: '24vw',
  })

  const onClickMenuOption = (query: string) => {
    navigate(query)
  }

  return (
    <Flex display={isOpen ? 'flex' : 'none'} position={'fixed'} zIndex={6}>
      <Flex>
        <Box
          height="100vh"
          borderRight="0.5px solid rgba(0,0,0,0.1)"
          position="relative"
          top="0"
          background="white"
          width={width}
        >
          <Flex>
            <Flex
              width="100%"
              height="4.875rem"
              alignItems="center"
              justifyContent="center"
              position={'relative'}
              bg="#F8F9FA"
            >
              <Image src={logo} alt="Logo" w="108px" />
              {closeButton}
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            px="2vw"
            overflow="auto"
            height="89vh"
            paddingBottom="3rem"
            css={{
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#cad4de',
                borderRadius: '24px',
              },
            }}
          >
            <NavItem
              mt="1rem"
              Icon={FaTh}
              title="Dashboard"
              description="Centralize seus dados"
              serviceNumber={1}
              onClick={() => onClickMenuOption('/dashboard')}
            />
            <NavItem
              mt="1rem"
              Icon={FaMapMarkerAlt}
              title="Pontos de venda"
              description="Crie e visualize os seus pontos"
              serviceNumber={3}
              onClick={() => onClickMenuOption('/pontos-de-venda')}
            />
            <NavItem
              mt="1rem"
              Icon={FaUserFriends}
              title="Grupos de Clientes"
              description="Gerencie seus grupos de clientes"
              serviceNumber={4}
              onClick={() => onClickMenuOption('/grupos-de-clientes')}
            />
            <NavItem
              mt="1rem"
              Icon={FaShoppingCart}
              title="Grupos de Produtos"
              description="Gerencie os seus produtos"
              serviceNumber={5}
              onClick={() => onClickMenuOption('/grupo-de-produtos')}
            />
            <NavItem
              mt="1rem"
              Icon={FaArchive}
              title="Produtos"
              description="Gerencie os produtos"
              serviceNumber={2}
              onClick={() => onClickMenuOption('/produtos')}
            />
            <NavItem
              mt="1rem"
              Icon={FaPollH}
              title="Benefícios"
              description="Crie e visualize os benefícios"
              serviceNumber={6}
              onClick={() => onClickMenuOption('/beneficios')}
            />

            <NavItem
              mt="1rem"
              Icon={FaUserCircle}
              title="Usuários do App"
              description="Gerencie os usuarios do app"
              serviceNumber={7}
              onClick={() => onClickMenuOption('/usuarios-do-app')}
            />
            <NavItem
              mt="1rem"
              Icon={FaBell}
              title="Notificações"
              description="Gerencie suas notificações"
              serviceNumber={8}
              onClick={() => onClickMenuOption('/notificacoes')}
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default SideBar
