import { Flex, Heading, HStack } from '@chakra-ui/layout'
import { Input } from 'components/Input/Input'
import Select from 'components/Select'

import { IPointsBenefitsFormProps } from '../BenefitsForm.interface'

const DescBenefitsForm = ({ formik, isEdit }: IPointsBenefitsFormProps) => {
  return (
    <Flex gap={'2rem'} flexDirection={'column'} background="white">
      <HStack width={'100%'}>
        <Heading fontWeight={'semibold'} size={'md'} alignSelf={'start'}>
          Descontos
        </Heading>
        <Input
          nothingLabel={true}
          display="flex"
          alignItems="center"
          variant="switch"
          name={'isconcederdescontoenabled'}
        />
      </HStack>

      <HStack width={'100%'} spacing={'8'}>
        <HStack width={'50%'} alignItems={'flex-end'}>
          <Select
            label="Referência"
            options={[
              { label: 'Em valor', value: 'V' },
              { label: 'Em percentual', value: 'P' },
            ]}
            placeholder="Selecione a referencia"
            width={'100%'}
            isDisabled={!formik.values.isconcederdescontoenabled || isEdit}
            variant={'filled'}
            errorMessage={formik.errors.referenciadesconto}
            name={'referenciadesconto'}
          />
        </HStack>
        <HStack width={'50%'}>
          <Input
            currency
            precision={'3'}
            prefix={
              formik.values.referenciadesconto === 'V' ? 'R$ ' : undefined
            }
            suffix={formik.values.referenciadesconto === 'P' ? ' %' : undefined}
            chakraVariant="filled"
            label={'Desconto'}
            placeholder="Digite o valor do desconto."
            name={'auferirdesconto'}
            isDisabled={
              !formik.values.isconcederdescontoenabled ||
              isEdit ||
              formik.values.referenciadesconto === ''
            }
          />
        </HStack>
      </HStack>
    </Flex>
  )
}

export default DescBenefitsForm
