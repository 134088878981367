import { useEffect, useState } from 'react'

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Heading,
  HStack,
} from '@chakra-ui/react'
import axios from 'axios'
import { Form, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import Button from '../../../components/Button'
import { Input } from '../../../components/Input/Input'
import { apiWS } from '../../../services'

interface IGroupOfClientsForm {
  id?: number
  descricao: string
  ativo: boolean
  protegido: boolean
}

interface IGroupOfClientsFormProps {
  initialValues: IGroupOfClientsForm
  isOpen: boolean
  onClose(id?: string): void
  isEdit?: boolean
}

const schema = yup.object().shape({
  descricao: yup.string().required('Campo obrigatório.'),
})

const GroupOfClientsForm = ({
  initialValues,
  isOpen,
  isEdit,
  onClose,
}: IGroupOfClientsFormProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const onSubmit = async (values: IGroupOfClientsForm) => {
    setIsLoading(true)
    try {
      const data = {
        id: values.id,
        descricao: values.descricao,
        ativo: values.ativo,
        protegido: values.protegido,
      }

      await apiWS.post('/WSAddGrupoCliente', data)

      onClose()
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
  })

  const onCancel = () => {
    formik.resetForm()
    onClose()
  }

  useEffect(() => {
    formik.setValues(initialValues)
  }, [initialValues])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent paddingBottom="2rem">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderRadius={'8px 8px 0px 0'}
          bg={'grey.300'}
        >
          <Heading fontWeight={'semibold'} size={'md'} lineHeight={'inherit'}>
            Novo grupo de cliente
          </Heading>

          <ModalCloseButton
            position={'relative'}
            top={'auto'}
            left={'auto'}
            _focus={{
              boxShadow: 'none',
              bg: '#fff',
            }}
            _hover={{
              bg: '#fff',
            }}
          />
        </ModalHeader>

        <ModalBody pt="2rem">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Flex mb={'32px'}>
                <Input
                  name="descricao"
                  label="Descrição"
                  value={formik.values.descricao}
                  isDisabled={isEdit}
                />
              </Flex>
              <Flex mb={'32px'} justifyContent={'space-between'}>
                <HStack>
                  <Input
                    label={'Ativo?'}
                    alignItems="center"
                    variant="switch"
                    name={'ativo'}
                  />
                </HStack>
                <HStack>
                  <Input
                    label={'Protegido?'}
                    alignItems="center"
                    variant="switch"
                    name={'protegido'}
                  />
                </HStack>
              </Flex>
              <Flex justifyContent={'space-between'}>
                <Button
                  text="Cancelar"
                  size="sm"
                  bg={'grey.300'}
                  color={'gray'}
                  onClick={onCancel}
                />

                <Button
                  text="Salvar"
                  type="submit"
                  size="sm"
                  loading={isLoading}
                />
              </Flex>
            </Form>
          </FormikProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default GroupOfClientsForm
