import { ChangeEvent, ReactNode } from 'react'
import { Search } from 'react-feather'
import InputMask from 'react-input-mask'

import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'

interface IProps {
  text: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: string
  cnpj?: boolean
  rightElement?: ReactNode
}

const SearchInput = ({
  text,
  onChange,
  value,
  cnpj = false,
  rightElement,
}: IProps) => {
  return (
    <InputGroup
      height="48px"
      backgroundColor="grey.300"
      alignItems="center"
      pl="1rem"
      borderRadius="8px"
      width="19rem"
      overflow="hidden"
      sx={{
        line: { color: 'grey.400' },
        circle: { color: 'grey.400' },
      }}
    >
      <Search />
      <Input
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore:next-line
        as={InputMask}
        mask={cnpj ? '99.999.999/9999-99' : ''}
        placeholder={text}
        onChange={onChange}
        variant="unstyled"
        ml="0.5rem"
        pt="0.1rem"
        value={value}
      />
      {rightElement && (
        <InputRightElement height="100%" pr={'2'}>
          {rightElement}
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default SearchInput
