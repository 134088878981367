import {
  PieChart as RechartPie,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { theme } from 'styles/theme'

import { IPieProps } from './PieChart.interface'

const COLORS = [
  theme.colors.service2[100],
  theme.colors.grey[400],
  theme.colors.service7[100],
  theme.colors.service5[100],
  theme.colors.service4[100],
]

const PieChart = ({ data }: IPieProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartPie>
        <Pie
          data={data}
          labelLine={true}
          // outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          cx={'50%'}
          cy={'50%'}
          innerRadius={55}
          outerRadius={100}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </RechartPie>
    </ResponsiveContainer>
  )
}

export default PieChart
