import React, { useEffect, useState } from 'react'

import { Flex, useToast } from '@chakra-ui/react'
import axios from 'axios'
import BarChart from 'components/BarChart/BarChart'
import { Loading } from 'components/Loading'
import Ranking from 'components/Ranking'
import { IRankingData } from 'components/Ranking/Ranking.interface'

import { apiWS } from '../../services'

interface IEncodeMonth {
  [key: string]: string
}

interface ISequenceMonth {
  [key: string]: number
}

const encodeMonth: IEncodeMonth = {
  Jan: 'Janeiro',
  Fev: 'Fevereiro',
  Mar: 'Março',
  Abr: 'Abril',
  Mai: 'Maio',
  Jun: 'Junho',
  Jul: 'Julho',
  Ago: 'Agosto',
  Set: 'Setembro',
  Out: 'Outubro',
  Nov: 'Novembro',
  Dez: 'Dezembro',
}

const sequenceMonth: ISequenceMonth = {
  Janeiro: 1,
  Fevereiro: 2,
  Março: 3,
  Abril: 4,
  Maio: 5,
  Junho: 6,
  Julho: 7,
  Agosto: 8,
  Setembro: 9,
  Outubro: 10,
  Novembro: 11,
  Dezembro: 12,
}

interface IDashResponse {
  mes: string
  nrotransacoes: number
  vendatotal: number
  descontosaauferidos: number
  ticketmedio: number
  descontomedio: number
}

interface ISalesData {
  mes: string
  'Total de Vendas': number
  'Ticket Médio': number
  'Nº de Vendas': number
}

interface IDiscountData {
  mes: string
  'Total de Descontos': number
  'Desconto Médio': number
  'Nº de Vendas': number
}

interface ISales {
  mes: string
  nrotransacoes: number
  vendatotal: number
  descontosauferidos: number
  ticketmedio: number
  descontomedio: number
}

interface IDashResponse {
  vendas: ISales[]
  rankinggrupoclientes: IRankingData[]
}

const Dashboard = () => {
  const [salesDash, setSalesDash] = useState<ISalesData[]>([])
  const [discountDash, setDiscountDash] = useState<IDiscountData[]>([])
  const [ranking, setRanking] = useState<IRankingData[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const getDash = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IDashResponse>('/WSDashboardWeb')

      setSalesDash(
        data.vendas
          .map((info) => {
            const [month] = info.mes.split('/')
            return {
              'Nº de Vendas': info.nrotransacoes,
              'Ticket Médio': info.ticketmedio,
              'Total de Vendas': info.vendatotal,
              mes: encodeMonth[month],
            }
          })
          .sort(
            (venda1, venda2) =>
              sequenceMonth[venda1.mes] - sequenceMonth[venda2.mes]
          )
      )

      setDiscountDash(
        data.vendas
          .map((info) => {
            const [month] = info.mes.split('/')
            return {
              'Nº de Vendas': info.nrotransacoes,
              mes: encodeMonth[month],
              'Desconto Médio': info.descontomedio,
              'Total de Descontos': info.descontosauferidos,
            }
          })
          .sort(
            (venda1, venda2) =>
              sequenceMonth[venda1.mes] - sequenceMonth[venda2.mes]
          )
      )

      setRanking(data.rankinggrupoclientes)

      if (!data) {
        throw new Error()
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getDash()
    setInterval(getDash, 1800000)
  }, [])

  return (
    <>
      <Loading isLoading={isLoading} />
      <Flex gap="2rem" direction={'column'} p={{ xs: '2', md: '10' }}>
        <Flex justifyContent={'space-around'} wrap="wrap" gap={'2rem'}>
          <BarChart
            title="Vendas"
            xAxisKey="mes"
            data={salesDash}
            dataKeys={['Total de Vendas', 'Ticket Médio', 'Nº de Vendas']}
          />
          <BarChart
            title="Descontos"
            xAxisKey="mes"
            data={discountDash}
            dataKeys={['Total de Descontos', 'Desconto Médio', 'Nº de Vendas']}
          />
        </Flex>

        <Ranking title="Ranking de Grupo de Clientes" data={ranking} />
      </Flex>
    </>
  )
}

export default Dashboard
