import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FaCheck, FaTimesCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Box, Flex, useToast, Button as ButtonChakra } from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Loading } from 'components/Loading'
import SearchInput from 'components/SearchInput'
import Table, { IData } from 'components/Table'

import { apiWS } from '../../services'
import { removeEspecialCharacter } from '../../utils/removeEspecialCharacter'
import { IBenefit } from './Benefits.interface'
import { headers } from './Benefits.tonstants'

const Benefits = () => {
  const navigate = useNavigate()

  const [benefits, setBenefits] = useState<IData[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [benefitsList, setBenefitsList] = useState<IBenefit[]>([])
  const [descricaoFilter, setDescricaoFilter] = useState('')
  const [filteredBenefits, setFilteredBenefits] = useState<IBenefit[]>([])

  const toast = useToast()

  const formatBenefitsData = (data: IBenefit[]) => {
    return data.map((benefit) => {
      return {
        descricao: benefit.descricao,
        vigenciainicial: benefit.vigenciainicial || 'Não definido',
        vigenciafinal: benefit.vigenciafinal || 'Não definido',
        auferirpontos: benefit.isauferirpontosenabled ? (
          <Flex justifyContent={'center'} w="100%">
            <FaCheck color="green" />
          </Flex>
        ) : (
          <Flex justifyContent={'center'} w="100%">
            <FaTimesCircle color="red" />
          </Flex>
        ),
        auferirdesconto: benefit.isconcederdescontoenabled ? (
          <Flex justifyContent={'center'} w="100%">
            <FaCheck color="green" />
          </Flex>
        ) : (
          <Flex justifyContent={'center'} w="100%">
            <FaTimesCircle color="red" />
          </Flex>
        ),
        auferircashback: benefit.isvalorcashbackenabled ? (
          <Flex justifyContent={'center'} w="100%">
            <FaCheck color="green" />
          </Flex>
        ) : (
          <Flex justifyContent={'center'} w="100%">
            <FaTimesCircle color="red" />
          </Flex>
        ),
        options: (
          <Flex justifyContent={'center'} w="100%">
            <Button
              text="Editar"
              onClick={() => navigate(`/beneficio/${benefit.id}`)}
              variant="small"
              width="100px"
            />
          </Flex>
        ),
      }
    })
  }
  const getBenefits = async () => {
    setIsLoading(true)
    try {
      const { data } = await apiWS.post<IBenefit[]>('/WSGetBeneficios')

      setBenefits(formatBenefitsData(data))
      setBenefitsList(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (descricaoFilter !== '') {
      setFilteredBenefits(
        benefitsList.filter((item) =>
          item.descricao.toLowerCase().includes(descricaoFilter.toLowerCase())
        )
      )
    } else {
      setFilteredBenefits([])
    }
  }, [descricaoFilter, benefitsList])

  useEffect(() => {
    getBenefits()
  }, [])

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <Box px="1.5rem">
        <Flex justifyContent="space-between" alignItems={'center'} gap="2rem">
          <Button
            text="Adicionar benefício"
            onClick={() => navigate('/novo-beneficio')}
            alignSelf={'end'}
            size="md"
          />
          <SearchInput
            text="Buscar por nome"
            onChange={(e) => {
              setDescricaoFilter(removeEspecialCharacter(e.target.value))
            }}
            value={descricaoFilter}
            rightElement={
              descricaoFilter && (
                <ButtonChakra
                  color="gray"
                  _hover={{
                    color: 'red',
                  }}
                  colorScheme={'invariant'}
                  size=""
                  _focus={{
                    boxShadow: 'none',
                  }}
                  onClick={() => setDescricaoFilter('')}
                >
                  <AiOutlineClose />
                </ButtonChakra>
              )
            }
          />
        </Flex>
        <Flex pb="3rem">
          <Table
            headers={headers}
            data={
              filteredBenefits.length > 0
                ? formatBenefitsData(filteredBenefits)
                : benefits
            }
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default Benefits
