import { useEffect, useState } from 'react'

import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import axios from 'axios'
import { Form, FormikProvider, useFormik } from 'formik'

import { IOptions } from '../../@types/shared'
import { useAuth } from '../../contexts/AuthContext/AuthContext'
import { apiWS } from '../../services'
import Button from '../Button'
import { Input } from '../Input/Input'
import Select from '../Select'

interface IUser {
  accountuid: string
  ativa: boolean
  cpfnanota: boolean
  emitirdocumentofiscal: boolean
  grupoclientedescricao: string
  grupoclienteid: number
  grupousuariodescricao: string
  grupousuarioid: number
  nome: string
}

interface IPropsOnSubmit {
  grupoclienteid: number
  ativo: boolean
  nome: string
}

interface IUserAppFormProps {
  options: IOptions[]
  initialValues: IPropsOnSubmit
  isOpen: boolean
  onClose(id?: string): void
}

const UserAppForm = ({
  initialValues,
  isOpen,
  onClose,
  options,
}: IUserAppFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUSers] = useState<IUser[]>([])

  const toast = useToast()
  const { token } = useAuth()

  const onSubmit = async (values: IPropsOnSubmit) => {
    setIsLoading(true)
    try {
      const user = users.find((item) => item.nome === initialValues.nome)

      const data = {
        accountuid: user?.accountuid,
        contaativa: values.ativo,
        grupoclienteid: values.grupoclienteid,
      }

      await apiWS.post('/WSSetAccount', data)

      onClose()
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit,
  })

  const onCancel = () => {
    formik.resetForm()
    onClose()
  }

  const getUsers = async () => {
    setIsLoading(true)
    try {
      const headers = { logintoken: token }
      const { data } = await apiWS.post<IUser[]>('/WSGetAccounts', headers)
      setUSers(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const selectGroup = (value: string) => {
    formik.setFieldValue('grupoclienteid', parseInt(value))
  }

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    formik.setValues(initialValues)
  }, [initialValues])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent paddingBottom="2rem">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderRadius={'8px 8px 0px 0'}
          bg={'grey.300'}
        >
          <Heading fontWeight={'semibold'} size={'md'} lineHeight={'inherit'}>
            Editar usuários
          </Heading>

          <ModalCloseButton
            position={'relative'}
            top={'auto'}
            left={'auto'}
            _focus={{
              boxShadow: 'none',
              bg: '#fff',
            }}
            _hover={{
              bg: '#fff',
            }}
          />
        </ModalHeader>

        <ModalBody pt="2rem">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Flex mb={'32px'}>
                <Select
                  handleChange={selectGroup}
                  options={options}
                  label={'Alterar Grupo de clientes'}
                  errorMessage={formik.errors.grupoclienteid}
                  name={'grupoclienteid'}
                  value={formik.values.grupoclienteid}
                />
              </Flex>
              <Flex mb={'32px'}>
                <Input
                  label={'Ativo?'}
                  alignItems="center"
                  variant="switch"
                  name={'ativo'}
                />
              </Flex>
              <Flex justifyContent={'space-between'}>
                <Button
                  text="Cancelar"
                  bg={'grey.300'}
                  minWidth={150}
                  color={'gray'}
                  onClick={onCancel}
                  size="sm"
                />

                <Button
                  text="Salvar"
                  type="submit"
                  minWidth={150}
                  size="sm"
                  isLoading={isLoading}
                />
              </Flex>
            </Form>
          </FormikProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default UserAppForm
