import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Divider, HStack, SimpleGrid } from '@chakra-ui/layout'
import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import Button from 'components/Button'
import { Header } from 'components/Header'
import { Loading } from 'components/Loading'
import { useFormik, Form, FormikProvider } from 'formik'
import { apiWS, geoApi } from 'services'

import { IPointOfSale } from '../../@types/pointsOfSale'
import { useLocations } from '../../contexts/locations'
import {
  removeEspecialCharacter,
  removeEspecialPontuation,
} from '../../utils/removeEspecialCharacter'
import InformationOfPoints from './components/InformationsOfPoints'
import LocationsInformationPoints from './components/LocationsInformationPoints'
import TimetablePoints from './components/TimetablePoints'
import { schema } from './PointsForm.constants'
import { IFormValues, IPointsOfSale } from './PointsForm.interface'

const PointsForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const navigate = useNavigate()

  const { cities, states } = useLocations()

  const toast = useToast()
  const params = useParams()

  const createPointOfSale = async (data: IPointsOfSale[]) => {
    setIsLoading(true)
    try {
      const {
        status,
        data: { message, httpstatus },
      } = await apiWS.post('/WSAddPontoVenda', data)
      if (status === 200) {
        toast({
          title: isEdit
            ? 'Dados alterados com sucesso'
            : 'Ponto de vendas cadastrado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        navigate('/pontos-de-venda')
      }
      if (httpstatus === 400) {
        toast({
          title: message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function onSubmit(values: IFormValues) {
    setIsLoading(true)
    try {
      const {
        ativo,
        cep,
        cidadenome,
        cnpj,
        complemento,
        logradouro,
        numero,
        razaosocial,
        ufnome,
        nomefantasia,
        horariofuncionamento,
      } = values

      const citie = cities.find((citie) => {
        return (
          removeEspecialCharacter(citie.nome) ===
          removeEspecialCharacter(values.cidadenome)
        )
      })

      if (citie) {
        const state = states.find((state) => state.id === parseInt(citie?.uf))
        if (state) {
          const {
            data: { results },
          } = await geoApi.get(`/json`, {
            params: {
              address: `${removeEspecialCharacter(
                cidadenome
              )},${ufnome},${logradouro},${numero}`,
            },
          })

          const [result] = results

          const lat = result.geometry.location.lat || 1
          const lng = result.geometry.location.lng || 1

          const data: IPointsOfSale[] = [
            {
              updatekind: 1,
              id: isEdit ? parseInt(params.id || '0') : 0,
              cnpj: removeEspecialPontuation(cnpj),
              razaosocial,
              cep: removeEspecialPontuation(cep),
              logradouro,
              numero: parseInt(numero) || 0,
              complemento,
              cidadeid: citie?.id,
              cidadenome,
              bairroid: 0,
              uf: state?.uf,
              ufnome,
              ativo,
              latitude: lat,
              longitude: lng,
              inputedbygps: false,
              nomefantasia,
              horariofuncionamento,
            },
          ]

          await createPointOfSale(data)
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      cnpj: '',
      razaosocial: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      cidadenome: '',
      bairro: '',
      ufnome: '',
      nomefantasia: '',
      avaliacao: 0.0,
      ativo: true,
      latitude: 0,
      longitude: 0,
      horariofuncionamento: [
        {
          diasemana: 1,
          vintequatrohrs: false,
          naoabre: false,
          horaabertura: '',
          horafechamento: '',
        },
        {
          diasemana: 2,
          vintequatrohrs: false,
          naoabre: false,
          horaabertura: '',
          horafechamento: '',
        },
        {
          diasemana: 3,
          vintequatrohrs: false,
          naoabre: false,
          horaabertura: '',
          horafechamento: '',
        },
        {
          diasemana: 4,
          vintequatrohrs: false,
          naoabre: false,
          horaabertura: '',
          horafechamento: '',
        },
        {
          diasemana: 5,
          vintequatrohrs: false,
          naoabre: false,
          horaabertura: '',
          horafechamento: '',
        },
        {
          diasemana: 6,
          vintequatrohrs: false,
          naoabre: false,
          horaabertura: '',
          horafechamento: '',
        },
        {
          diasemana: 7,
          vintequatrohrs: false,
          naoabre: false,
          horaabertura: '',
          horafechamento: '',
        },
      ],
    },
    validationSchema: schema,
    onSubmit,
  })

  const getPoint = async (id: string) => {
    setIsLoading(true)

    try {
      const { data } = await apiWS.get<IPointOfSale[]>('/WSGetPontoVenda', {
        params: {
          id,
        },
      })

      const [
        {
          ativo,
          cep,
          cidadenome,
          cnpj,
          complemento,
          logradouro,
          numero,
          razaosocial,
          ufnome,
          nomefantasia,
          avaliacao,
          horariofuncionamento,
          latitude,
          longitude,
        },
      ] = data.filter((point) => point.id === parseInt(id))

      formik.setValues({
        ativo: ativo === 1 ? true : false,
        bairro: '',
        cep,
        cidadenome,
        cnpj,
        complemento,
        logradouro,
        numero: numero === 0 ? 'SN' : String(numero),
        razaosocial,
        ufnome,
        nomefantasia,
        latitude,
        longitude,
        avaliacao: avaliacao || 0.0,
        horariofuncionamento: horariofuncionamento.length
          ? horariofuncionamento
          : formik.values.horariofuncionamento,
      })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (params.id) {
      setIsEdit(true)
      getPoint(params.id)
    }
  }, [params.id])

  return (
    <Box>
      <Box padding={{ xl: '6', md: '6', lg: '8' }} background="grey.200">
        <Loading isLoading={isLoading} />

        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <SimpleGrid
              spacing={'6'}
              maxWidth={1280}
              margin={'0 auto'}
              boxShadow={'0px 3.45362px 34.5362px rgba(170, 170, 170, 0.25);'}
              padding={'8'}
              background="white"
            >
              <InformationOfPoints
                formik={formik}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
              />

              <Divider mt={'1rem'} />

              <LocationsInformationPoints
                formik={formik}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
              />

              <Divider mt={'1rem'} />

              <TimetablePoints formik={formik} />

              <HStack justifyContent={'flex-end'}>
                <Button
                  color="gray"
                  text="Cancelar"
                  minWidth={150}
                  onClick={() => navigate(-1)}
                />
                <Button
                  text={isEdit ? 'Salvar' : 'Cadastrar'}
                  minWidth={150}
                  type="submit"
                  bg={'green.500'}
                  _hover={{
                    backgroundColor: 'green.700',
                  }}
                />
              </HStack>
            </SimpleGrid>
          </Form>
        </FormikProvider>
      </Box>
    </Box>
  )
}

export default PointsForm
