import { ReactNode } from 'react'
import CurrencyInput from 'react-currency-input'
import InputMask from 'react-input-mask'

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputProps,
  InputRightElement,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react'
import { useField } from 'formik'

interface ITestareaProps extends TextareaProps {
  name: string
  label?: string
}

/*interface IInputProps extends InputProps {
  name: string
  label?: string
  width?: string | number
  rightElement?: ReactNode
  mask?: string | Array<string | RegExp>
  chakraVariant?: string
  nothingLabel?: boolean
  displayFlex?: boolean
  isChecked?: boolean
  maskChar?: string
  currency?: boolean
  suffix?: string
  precision?: string
} */

const ITextarea = ({ name, label, ...rest }: ITestareaProps) => {
  const [field, meta] = useField(name)

  return (
    <FormControl isInvalid={meta.touched && !!meta.error}>
      {label && (
        <FormLabel htmlFor={field.name} width={'full'}>
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Textarea
          precision={3}
          onChangeEvent={field.onChange}
          decimalSeparator=","
          thousandSeparator="."
          {...rest}
          {...field}
          _focus={{
            borderColor: 'green.500',
          }}
          id={field.name}
          background="grey.200"
          height="141px"
        />
      </InputGroup>

      <FormErrorMessage position="absolute" maxH={'8'}>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  )
}

export default ITextarea
