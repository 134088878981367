import { useState } from 'react'
import { IconType } from 'react-icons'

import { Flex, FlexProps, Text } from '@chakra-ui/react'

interface IProps extends FlexProps {
  Icon: IconType
  title: string
  description: string
  serviceNumber: number
  onClick: () => void
}

const NavItem = ({
  serviceNumber,
  Icon,
  title,
  description,
  onClick,
  ...rest
}: IProps) => {
  const [bgColor, setBgColor] = useState<string>(`service${serviceNumber}.50`)
  const [iconColor, setIconColor] = useState<string>(
    `service${serviceNumber}.100`
  )

  return (
    <Flex
      w="100%"
      border="0.5px solid white"
      borderRadius="8"
      px="1rem"
      py="1rem"
      alignItems="center"
      cursor="pointer"
      {...rest}
      sx={{ svg: { fill: `${iconColor}` } }}
      _hover={{
        borderColor: `service${serviceNumber}.100`,
      }}
      onMouseOver={() => {
        setBgColor(`service${serviceNumber}.100`)
        setIconColor('white')
      }}
      onMouseOut={() => {
        setBgColor(`service${serviceNumber}.50`)
        setIconColor(`service${serviceNumber}.100`)
      }}
      onClick={onClick}
    >
      <Flex
        bg={bgColor}
        borderRadius="50"
        w={50}
        h={50}
        alignItems="center"
        justifyContent="center"
        role="icon"
      >
        <Icon />
      </Flex>
      <Flex w="80%" flexDirection="column" textAlign="justify">
        <Text
          mb="0.3rem"
          ml="0.8rem"
          fontSize="sm"
          letterSpacing="1px"
          fontWeight="bold"
        >
          {title}
        </Text>
        <Text
          ml="0.8rem"
          color="grey.400"
          fontSize="xs"
          letterSpacing="1px"
          whiteSpace="nowrap"
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  )
}

export default NavItem
