import { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from './AuthContext/AuthContext'
import { MenuProvider } from './MenuContext/MenuContext'

interface ProvidersProps {
  children: ReactNode
}

const Providers = ({ children }: ProvidersProps) => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <MenuProvider>{children}</MenuProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default Providers
